import styled from 'styled-components';

export const MainButton = styled.a`
    width: 140px;
    border: 0;
    border: 2px solid ${({ theme }) => theme.colors.secondary};
    height: 30px;
    line-height: 30px;
    display: block;
    background: linear-gradient(to right, ${({ theme }) => theme.colors.secondary} 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.5s ease-out;
    color: ${({ color }) => color ?? 'black'};
    margin-top: 40px;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    font-size: ${({ theme }) => theme.font.size.lg};
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    letter-spacing: 1px;
    cursor: pointer;

    &:hover {
        color: white;
        background-position: left bottom;
    }

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        margin-top: 30px;
        margin-bottom: 30px;
        align-self: center;
    }
`;
