import styled from 'styled-components';

export const StyledDrawer = styled.div`
    background: white;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    left: -100%;
    top: 70px;

    ${({ theme }) => theme.mediaBreakpoints.navbarChange} {
        display: block;
    }
`;

export const StyledLink = styled.a`
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: linear-gradient(to right, #ffffff99, #ebebeb, #ffffff99);
    }
`;

export const IconWrapper = styled.div`
    flex: 2;
`;

export const LinkText = styled.p`
    flex: 2;
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-size: 15px;
    color: black;
`;

export const LineDivider = styled.p`
    color: ${({ theme }) => theme.colors.secondary};
    width: 80px;
    margin: 0;
    text-align: center;
`;
