// Website images
import imageMediaBrowser from './../../assets/images/websites/mediabrowser.png';
import imageFootballClub from './../../assets/images/websites/footballclub.png';
import imageRMS from './../../assets/images/websites/rms.png';
import imagePortfolioCreator from './../../assets/images/websites/progr4mm3r.png';
import imageToDo from './../../assets/images/websites/todo.png';

// Project images
import mediaBrowser from './../../assets/images/projects/media-browser.png';
import portfolioCreator from './../../assets/images/projects/progr4mm3r.png';
import footballClub from './../../assets/images/projects/football-club.png';
import restaurantManagementSystem from './../../assets/images/projects/rms.png';
import toDo from './../../assets/images/projects/todo.png';
import quotesService from './../../assets/images/projects/quotes-service.png';
import rng from './../../assets/images/projects/rng.png';
import onlineLibrary from './../../assets/images/projects/online-library.png';
import connectedWebsite from './../../assets/images/projects/c0nnected.png';
import spaceships from './../../assets/images/projects/spaceships.png';
import apocalyptoGame from './../../assets/images/projects/apocalypto-game.png';
import quizApp from './../../assets/images/projects/quiz-app.png';
import imageBinarizer from './../../assets/images/projects/image-binarizer.png';
import databaseApp from './../../assets/images/projects/database-app.png';
import databaseAnalyzer from './../../assets/images/projects/database-analyzer.png';
import taskPlanner from './../../assets/images/projects/task-planner.png';
import javaGoodPractices from './../../assets/images/projects/java-good-practices.png';
import algen from './../../assets/images/projects/algen.png';
import inspyred from './../../assets/images/projects/inspyred.png';
import pizzeriaDatabase from './../../assets/images/projects/pizzeria-database.png';
import carServiceStation from './../../assets/images/projects/car-service-station.png';
import mediaBrowserLogo from './../../assets/images/projects/media-browser-logo.png';
import portfolioCreatorLogo from './../../assets/images/projects/progr4mm3r-logo.png';
import footballClubLogo from './../../assets/images/projects/football-club-logo.png';
import rmsWebsiteLogo from './../../assets/images/projects/rms-logo.png';
import toDoWebsiteLogo from './../../assets/images/projects/todo-logo.png';
import connectedLogo from './../../assets/images/projects/c0nnected-logo.png';
import quotesServiceLogo from './../../assets/images/projects/quotes-service-logo.png';
import rngLogo from './../../assets/images/projects/rng-logo.png';
import onlineLibraryLogo from './../../assets/images/projects/online-library-logo.png';

// Competitions projects images
import cameraFingerprint from './../../assets/images/projects/camera-fingerprint.png';
import iOSDeviceDataExtractor from './../../assets/images/projects/ios-dde.png';

// Competitions images
import OSDFConLogoLarge from './../../assets/images/competitions/osdfcon-large.png';
import OSDFConLogoSmall from './../../assets/images/competitions/osdfcon-small.png';

// Languages images
import JavaLogo from './../../assets/images/languages/java.png';
import PythonLogo from './../../assets/images/languages/python.png';

// Technologies images
import AutopsyLogo from './../../assets/images/technologies/autopsy.png';
import NetBeansLogo from './../../assets/images/technologies/netbeans.png';

export const websites = [
    {
        name: 'toDO - plan your future',
        image: imageToDo,
        url: 'https://planyourfuture.netlify.app',
        color: '#DC143C',
        backgroundColor: '#000000',
        description: {
            en: 'To-Do application that allows you to manage tasks to be performed.',
            pl: 'Aplikacja To-Do pozwalająca zarządzać zadaniami do wykonania.',
        },
    },
    {
        name: 'Progr4mm3r',
        image: imagePortfolioCreator,
        url: 'https://progr4mm3r.netlify.app',
        color: '#FFA500',
        backgroundColor: '#000000',
        description: {
            en: 'Programmer portfolio creator. First project I created using React.js.',
            pl: 'Kreator portfolio programisty. Pierwszy projekt, który stworzyłem z wykorzystaniem React.js.',
        },
    },
    {
        name: 'Media Browser',
        image: imageMediaBrowser,
        url: 'https://youtu.be/7r1r9JiKsYc',
        color: '#1E90FF',
        backgroundColor: '#F2F2F2',
        description: {
            en: 'System for searching information about audiovisual materials.',
            pl: 'System do wyszukiwania informacji o materiałach audiowizualnych.',
        },
    },
    {
        name: 'FOOTBALL Club',
        image: imageFootballClub,
        url: 'https://footballclubapp.netlify.app',
        color: '#1BA622',
        backgroundColor: '#1F1F1F',
        description: {
            en: 'Football club website template. The website presents information about the club.',
            pl: 'Szablon klubu piłkarskiego. Na stronie przedstawione są informacje dotyczące klubu.',
        },
    },
    {
        name: 'RMS',
        image: imageRMS,
        url: 'https://restaurantmanagementsystem.netlify.app',
        color: 'deepskyblue',
        backgroundColor: '#FFFFFF',
        description: {
            en: 'System that allows you to manage a restaurant.',
            pl: 'System pozwalający na zarządzanie restauracją.',
        },
    },
];

export const projects = [
    {
        name: 'Media Browser',
        type: {
            en: 'Mobile application for Android & iOS',
            pl: 'Aplikacja mobilna na systemy Android i iOS',
        },
        shortDescription: {
            en: 'System for searching information about audiovisual materials. Project implemented as part of an engineering thesis.',
            pl: 'System do wyszukiwania informacji o materiałach audiowizualnych. Projekt realizowany w ramach pracy inżynierskiej.',
        },
        description: {
            en: 'System for searching information about audiovisual materials. Project implemented as part of an engineering thesis. The application allows you to search and view information about movies, series and people related to the film industry. The application is designed for devices with Android and iOS. The application supports Polish and English.',
            pl: 'System do wyszukiwania informacji o materiałach audiowizualnych. Projekt realizowany w ramach pracy inżynierskiej. Aplikacja umożliwia wyszukiwanie i przeglądanie informacji o filmach, serialach i osobach związanych z branżą filmową. Aplikacja przeznaczona jest na urządzenia z systemem Android oraz iOS. Obsługuje język polski i angielski.',
        },
        technologies: {
            application: [
                'JavaScript',
                'React Native',
                'RN Navigation',
                'RN SVG',
                'RN Elements',
                'RN Paper',
                'MobX',
            ],
            server: [
                'JavaScript',
                'Node.js',
                'Hapi.js',
                'nodemon',
                'JSON Web Token',
                'Joi',
                'bcrpyt',
                'Nodemailer',
                'lodash',
            ],
            database: ['MongoDB', 'mongoose', 'SQLite'],
        },
        os: ['Android', 'iOS'],
        languages: ['PL', 'US'],
        image: mediaBrowser,
        links: {
            github: 'https://github.com/ernestbies/MediaBrowser-mobile',
            youtube: 'https://youtu.be/7r1r9JiKsYc',
            additional: {
                title: {
                    en: 'API server documentation',
                    pl: 'Dokumentacja serwera API',
                },
                url: 'https://mediabrowser-backend.onrender.com/documentation',
                logo: mediaBrowserLogo,
            },
        },
    },
    {
        name: 'FOOTBALL Club',
        type: {
            en: 'Responsive website',
            pl: 'Responsywna strona internetowa',
        },
        shortDescription: {
            en: 'Football club website template. The website presents information about the club. The project also includes an administration panel for managing content on the website.',
            pl: 'Szablon klubu piłkarskiego. Na stronie przedstawione są informacje dotyczące klubu. Projekt zawiera również panel administracyjny do zarządzania treścią na stronie.',
        },
        description: {
            en: 'Football club website template. The website presents information about the club, reports and details of statistics. The project also includes an administration panel for managing content on the website.',
            pl: 'Szablon strony klubu piłkarskiego. Na stronie przedstawione są informacje dotyczące klubu, relacje oraz szczegółowe statystyki. Projekt zawiera również panel administracyjny do zarządzania treścią na stronie.',
        },
        technologies: {
            application: [
                'JavaScript',
                'HTML',
                'CSS',
                'React.js',
                'React Router',
                'React Redux',
                'Styled Components',
                'Font Awesome',
                'Material UI',
                'React Spinners',
            ],
            server: ['JavaScript', 'Node.js', 'Hapi.js', 'JWT (JSON Web Token)', 'bcrypt'],
            database: ['MongoDB', 'mongoose'],
        },
        os: ['Internet application'],
        languages: ['PL'],
        image: footballClub,
        links: {
            github: 'https://github.com/ernestbies/football-club',
            additional: {
                title: {
                    en: 'FOOTBALL Club - template',
                    pl: 'FOOTBALL Club - szablon klubu piłkarskiego',
                },
                url: 'https://footballclubapp.netlify.app',
                logo: footballClubLogo,
            },
        },
    },
    {
        name: 'toDo',
        type: {
            en: 'Responsive website',
            pl: 'Responsywna strona internetowa',
        },
        shortDescription: {
            en: 'To-Do application that allows you to manage tasks to be performed.',
            pl: 'Aplikacja To-Do pozwalająca zarządzać zadaniami do wykonania.',
        },
        description: {
            en: 'To-Do application that allows you to manage tasks to be performed.',
            pl: 'Aplikacja To-Do pozwalająca zarządzać zadaniami do wykonania.',
        },
        technologies: {
            application: [
                'JavaScript',
                'HTML',
                'CSS',
                'React.js',
                'React Router',
                'Styled Components',
                'Redux',
            ],
        },
        os: ['Internet application'],
        languages: ['US'],
        image: toDo,
        links: {
            github: 'https://github.com/ernestbies/todo-list',
            additional: {
                title: {
                    en: 'toDo - plan your future',
                    pl: 'Strona internetowa toDo',
                },
                url: 'https://planyourfuture.netlify.app',
                logo: toDoWebsiteLogo,
            },
        },
    },
    {
        name: 'c0nnected_',
        type: {
            en: 'Responsive website',
            pl: 'Responsywna strona internetowa',
        },
        shortDescription: {
            en: 'c0nnected_ is a chat application created with React. The chat works on the basis of the Hall of Fame principles.',
            pl: 'c0nnected_ to aplikacja do czatowania stworzona z wykorzystaniem React.js. Czat działa w oparciu o zasady Hall of Fame.',
        },
        description: {
            en: 'c0nnected_ is a chat application created with React. The chat works on the basis of the Hall of Fame principles. The user has to solve the puzzle to leave his message on the board. There is only one message for each user.',
            pl: 'c0nnected_ to aplikacja do czatowania stworzona z wykorzystaniem React.js. Czat działa w oparciu o zasady Hall of Fame. Użytkownik musi rozwiazać zagadkę by zostawić swoją wiadomość na tablicy. Na każdego użytkownika przypada tylko jedna wiadomość.',
        },
        technologies: {
            application: ['JavaScript', 'HTML', 'CSS', 'React.js', 'React Router', 'Styled Components'],
        },
        os: ['Internet application'],
        languages: ['US'],
        image: connectedWebsite,
        links: {
            github: 'https://github.com/ernestbies/c0nnected_',
            additional: {
                title: {
                    en: 'c0nnected_ - STAY CONNECTED',
                    pl: 'c0nnected_ - STAY CONNECTED',
                },
                url: 'https://c0nnected.netlify.app',
                logo: connectedLogo,
            },
        },
    },
    {
        name: 'RMS',
        type: {
            en: 'Responsive website',
            pl: 'Responsywna strona internetowa',
        },
        shortDescription: {
            en: 'Restaurant Management System is a system that allows you to manage a restaurant - adding, editing and removing products.',
            pl: 'Restaurant Management System to system pozwalający na zarządzanie restauracją - dodawanie, edycja oraz usuwanie produktów.',
        },
        description: {
            en: 'Restaurant Management System is a system that allows you to manage a restaurant (adding, editing and removing products). The project consists of the frontend and backend layers. Basic HTTP methods such as GET, POST, PUT and DELETE were used in the project. The website contains a form for adding new objects and tables containing individual data about the products on the restaurant menu. The project was created using React.js and Express.js.',
            pl: 'Restaurant Management System to system pozwalający na zarządzanie restauracją (dodawanie, edycja oraz usuwanie produktów). Projekt składa się z warstwy frontend oraz backend. W projekcie wykorzystano podstawowe metody HTTP takie jak GET, POST, PUT oraz DELETE. Strona zawiera formularz dodawania nowych obiektów oraz tabele zawierające poszczególne dane dotyczące produktów znajdujących się w menu restauracji. Projekt powstał z wykorzystaniem React.js oraz Express.js.',
        },
        technologies: {
            application: ['JavaScript', 'HTML', 'CSS', 'React.js', 'React Router', 'Styled Components'],
            server: ['JavaScript', 'Node.js', 'express.js'],
            database: ['MongoDB', 'mongoose'],
        },
        os: ['Internet application'],
        languages: ['US'],
        image: restaurantManagementSystem,
        links: {
            github: 'https://github.com/ernestbies/restaurant-management',
            additional: {
                title: {
                    en: 'Restaurant Management System (RMS)',
                    pl: 'System zarządzania restauracją (RMS)',
                },
                url: 'https://restaurantmanagementsystem.netlify.app',
                logo: rmsWebsiteLogo,
            },
        },
    },
    {
        name: 'Progr4mm3r',
        type: {
            en: 'Responsive website',
            pl: 'Responsywna strona internetowa',
        },
        shortDescription: {
            en: 'Programmer portfolio creator and template. It was the first website I created using React.js.',
            pl: 'Kreator oraz szablon portfolio programisty. Była to pierwsza strona internetowa, którą stworzyłem z wykorzystaniem React.js.',
        },
        description: {
            en: 'Programmer portfolio creator and template. It was the first website I created using React.js. While creating this project, I learned how to use the JavaScript scripting language and how to design websites with the use of HTML and CSS.',
            pl: 'Strona przedstawiająca moją osobę oraz moje projekty. Była to pierwsza strona internetowa, którą stworzyłem z wykorzystaniem React.js. Podczas tworzenia tego projektu uczyłem się jak używać języka skryptowego JavaScript oraz jak projektować strony internetowe z wykorzystaniem HTML oraz CSS.',
        },
        technologies: {
            application: [
                'JavaScript',
                'HTML',
                'CSS',
                'React.js',
                'React Router',
                'Bootstrap',
                'i18next',
                'Styled Components',
                'Prop Types',
                'Local Storage',
                'Formik',
                'Yup',
            ],
        },
        os: ['Internet application'],
        languages: ['PL', 'US'],
        image: portfolioCreator,
        links: {
            github: 'https://github.com/ernestbies/progr4mm3r',
            additional: {
                title: {
                    en: 'Progr4mm3r - portfolio creator',
                    pl: 'Progr4mm3r - kreator portfolio',
                },
                url: 'https://progr4mm3r.netlify.app',
                logo: portfolioCreatorLogo,
            },
        },
    },
    {
        name: 'Quotes Service',
        type: {
            en: 'Responsive website',
            pl: 'Responsywna strona internetowa',
        },
        shortDescription: {
            en: 'An application that downloads quotes from the API.',
            pl: 'Aplikacja pobierająca cytaty z zewnętrznego serwera API.',
        },
        description: {
            en: 'An application that downloads quotes from the API. The user can draw another quote or return to the previous one.',
            pl: 'Aplikacja pobierająca cytaty z API. Użytkownik może wylosować kolejny cytat lub wrócić do poprzedniego.',
        },
        technologies: {
            application: [
                'JavaScript',
                'HTML',
                'CSS',
                'React.js',
                'Styled Components',
                'Redux',
                'Redux Thunk',
            ],
        },
        os: ['Internet application'],
        languages: ['US', 'PL'],
        image: quotesService,
        links: {
            github: 'https://github.com/ernestbies/quotes-service',
            additional: {
                title: {
                    en: 'Quotes Service',
                    pl: 'Serwis Cytatów',
                },
                url: 'https://ernestbies.github.io/quotes-service',
                logo: quotesServiceLogo,
            },
        },
    },
    {
        name: 'Online library',
        type: {
            en: 'Responsive website',
            pl: 'Responsywna strona internetowa',
        },
        shortDescription: {
            en: 'Online library created with JavaScript, HTML and CSS only.',
            pl: 'Biblioteka online utworzona wyłącznie za pomocą JavaScript, HTML i CSS.',
        },
        description: {
            en: 'Online library created with JavaScript, HTML and CSS only. The application allows you to create your own book library. The data is saved in localStorage. The book can be added by filling out the form on the website. The presented table contains a list of all books and the possibility of deleting a particular item.',
            pl: 'Biblioteka online utworzona wyłącznie za pomocą JavaScript, HTML i CSS. Aplikacja umożliwia stworzenie własnej biblioteki książek. Dane są zapisywane w localStorage. Książkę można dodać wypełniając formularz na stronie. Prezentowana tabela zawiera spis wszystkich książek oraz możliwość skasowania danej pozycji.',
        },
        technologies: {
            application: ['JavaScript', 'HTML', 'CSS'],
        },
        os: ['Internet application'],
        languages: ['US'],
        image: onlineLibrary,
        links: {
            github: 'https://github.com/ernestbies/online-library',
            additional: {
                title: {
                    en: 'Online library',
                    pl: 'Biblioteka online',
                },
                url: 'https://ernestbies.github.io/online-library',
                logo: onlineLibraryLogo,
            },
        },
    },
    {
        name: 'Random Number Generator',
        type: {
            en: 'Responsive website',
            pl: 'Responsywna strona internetowa',
        },
        shortDescription: {
            en: 'Random number generator created with HTML, CSS and JavaScript only.',
            pl: 'Generator liczb losowych stworzony wyłącznie za pomocą HTML, CSS i JavaScript.',
        },
        description: {
            en: 'Random number generator created with HTML, CSS and JavaScript only. After clicking on the button the application will generate 20 random integers between 1 and 100 and put them in two columns — even and odd.',
            pl: 'Generator liczb losowych stworzony wyłącznie za pomocą HTML, CSS i JavaScript. Po kliknięciu przycisku aplikacja wygeneruje 20 losowych liczb całkowitych od 1 do 100 i umieści je w dwóch kolumnach — parzystych i nieparzystych.',
        },
        technologies: {
            application: ['JavaScript', 'HTML', 'CSS'],
        },
        os: ['Internet application'],
        languages: ['US'],
        image: rng,
        links: {
            github: 'https://github.com/ernestbies/random-number-generator',
            additional: {
                title: {
                    en: 'Random Number Generator',
                    pl: 'Generator liczb losowych',
                },
                url: 'https://ernestbies.github.io/random-number-generator',
                logo: rngLogo,
            },
        },
    },
    {
        name: 'Spaceships',
        type: {
            en: 'Game for PC & Android mobile devices',
            pl: 'Gra na PC oraz urządzenia mobilne z systemem Android',
        },
        shortDescription: {
            en: 'Spaceships is a puzzle game combining two popular games Ships and Minesweeper.',
            pl: 'Statki kosmiczne to gra logiczna, która łączy w sobie dwie popularne gry Statki i Saper.',
        },
        description: {
            en: 'Spaceships is a puzzle game combining two popular games "Ships" and "Minesweeper". Project is based on a client-server architecture. The aim of the game is to find all the ships on the board in the fewest possible steps.',
            pl: 'Statki kosmiczne to gra logiczna, która łączy w sobie dwie popularne gry „Statki” i „Saper”. Projekt oparty jest na architekturze klient-serwer. Celem gry jest znalezienie wszystkich statków na planszy w jak najmniejszej liczbie kroków. ',
        },
        technologies: {
            application: ['Java', 'C++', 'Apache Netbeans IDE', 'Swing', 'Qt', 'Android Studio'],
            server: ['Java', 'Spring', 'C++', 'Qt', 'Postman'],
            database: ['PostgreSQL', 'Java Persistence API'],
        },
        os: ['Windows', 'Linux', 'Android'],
        languages: ['PL'],
        image: spaceships,
        links: {
            github: 'https://github.com/ernestbies/SpaceShips',
        },
    },
    {
        name: 'ApocalyptoGame',
        type: {
            en: 'Game for PC',
            pl: 'Gra na PC',
        },
        shortDescription: {
            en: 'ApocalyptoGame is a first-person shooter (FPS) game. In the game, we play the role of a soldier set in a post-apocalyptic world.',
            pl: 'ApocalyptoGame to strzelanka z perspektywy pierwszej osoby (FPS). W grze wcielamy się w żołnierza osadzonego w postapokaliptycznym świecie.',
        },
        description: {
            en: "ApocalyptoGame is a first-person shooter (FPS) game. In the game, we play the role of a soldier set in a post-apocalyptic world. The player's task is to destroy the enemy ship. The player has the ability to interact with the environment, can pick up items. In order to destroy a ship, the player must collect four cells that will help him power the generator. The way to get cells is different. After collecting all the cells, the player can power the generator on the map, which will blow up the enemy ship. The game has a developed combat and healing system.",
            pl: 'ApocalyptoGame to strzelanka z perspektywy pierwszej osoby (FPS). W grze wcielamy się w żołnierza osadzonego w postapokaliptycznym świecie. Zadaniem gracza jest zniszczenie wrogiego statku. Gracz ma możliwość interakcji z otoczeniem, może podnosić przedmioty. Aby zniszczyć statek, gracz musi zebrać cztery ogniwa, które pomogą mu zasilić generator. Sposób zdobywania ogniw jest różny. Po zebraniu wszystkich ogniw gracz może zasilić znajdujący się na mapie generator, który wysadzi wrogi statek kosmiczny w powietrze. Gra posiada rozbudowany system walki i leczenia.',
        },
        technologies: {
            application: ['C#', 'Unity'],
        },
        os: ['Windows'],
        languages: ['PL'],
        image: apocalyptoGame,
        links: {
            github: 'https://github.com/ernestbies/ApocalyptoGame',
        },
    },
    {
        name: 'Quiz app',
        type: {
            en: 'Mobile application for Android & iOS',
            pl: 'Aplikacja mobilna na systemy Android i iOS',
        },
        shortDescription: {
            en: 'Mobile application that allows you to solve on-line tests. Tests are downloaded from an external server.',
            pl: 'Aplikacja mobilna umożliwiająca rozwiązywanie testów on-line. Testy są pobierane z serwera zewnętrznego.',
        },
        description: {
            en: 'Mobile application that allows you to solve on-line tests. Tests are downloaded from an external server. The user has the option of solving a specific test and has a specific time to answer a question. In addition, user can choose a random test and view results. The application has a drawer menu that makes it easy to access all screens. The application is designed for Android and iOS.',
            pl: 'Aplikacja mobilna umożliwiająca rozwiązywanie testów on-line. Testy są pobierane z serwera zewnętrznego. Użytkownik ma możliwość rozwiązania konkretnego testu i ma określony czas na udzielenie odpowiedzi na pytanie. Dodatkowo użytkownik może wybrać losowy test i przejrzeć wyniki. Aplikacja posiada menu wysuwane, które ułatwia dostęp do wszystkich ekranów. Przeznaczona jest na systemy Android i iOS.',
        },
        technologies: {
            application: [
                'JavaScript',
                'React Native',
                'RN Navigation',
                'Async Storage',
                'NetInfo',
                'Vector icons',
                'SQLite',
            ],
        },
        image: quizApp,
        os: ['Android', 'iOS'],
        languages: ['PL'],
        links: {
            github: 'https://github.com/ernestbies/Quiz',
        },
    },
    {
        name: 'Image Binarizer',
        type: {
            en: 'Desktop application',
            pl: 'Aplikacja desktopowa',
        },
        shortDescription: {
            en: 'The application allows you to binarize images. The application supports the basic types of binarization.',
            pl: 'Aplikacja umożliwia binaryzację obrazów. Aplikacja obsługuje różne typy binaryzacji.',
        },
        description: {
            en: 'The application allows you to binarize images. The application supports the basic types of binarization - low-level, high-level, two-threshold and conditional. It allows you to load any image (color or black and white), and then select a specific operation. The application works in a distributed environment using Remote Method Invocation (RMI) - a mechanism that enables remote methods of objects invocation. These objects can reside in other Java virtual machines.',
            pl: 'Aplikacja umożliwia binaryzację obrazów. Aplikacja obsługuje podstawowe typy binaryzacji - niskopoziomowe, wysokopoziomowe, dwuprogowe oraz warunkowe. Umożliwia wczytanie dowolnego obrazu (kolorowego lub czarno-białego), a następnie wybranie określonej operacji. Aplikacja działa w środowisku rozproszonym z wykorzystaniem Remote Method Invocation (RMI) - mechanizmu umożliwiającego zdalne wywoływanie obiektów. Te obiekty mogą znajdować się na innych maszynach wirtualnych Java.',
        },
        technologies: {
            application: ['Java', 'Apache NetBeans IDE', 'Swing', 'Remote Method Invocation (RMI)'],
        },
        image: imageBinarizer,
        os: ['Windows', 'Linux'],
        languages: ['PL'],
        links: {
            github: 'https://github.com/ernestbies/ImageBinarizer',
        },
    },
    {
        name: 'Database Connection App',
        type: {
            en: 'Desktop application',
            pl: 'Aplikacja desktopowa',
        },
        shortDescription: {
            en: 'The application allows you to connect to any PostgreSQL database using the JDBC engine and viewing tables in the database.',
            pl: 'Aplikacja umożliwia połączenie się z dowolną bazą danych PostgreSQL za pomocą silnika JDBC oraz przeglądanie tabel znajdujących się w bazie.',
        },
        description: {
            en: "The application allows you to connect to any PostgreSQL database using the JDBC engine. The login data can be saved and read because the application uses serialization. In the application, it is possible to view all tables in the database. If the table contains users' contact details (e-mail address), it is possible to send a message. Sending e-mails is performed by Javax Mail. User can add recipients (TO, CC, BCC), message subject and content. After approval, the e-mail is sent to the selected users.",
            pl: 'Aplikacja umożliwia połączenie się z dowolną bazą danych PostgreSQL za pomocą silnika JDBC. Dane logowania można zapisywać i odczytywać, ponieważ aplikacja korzysta z serializacji. W aplikacji istnieje możliwość podglądu wszystkich tabel w bazie danych. Jeżeli w tabeli znajdują się dane kontaktowe użytkowników (adres e-mail), istnieje możliwość wysłania wiadomości. Wysyłanie wiadomości e-mail jest realizowane przez Javax Mail. Użytkownik może dodać odbiorców (DO, DW, UDW), temat i treść wiadomości. Po zatwierdzeniu, wiadomość e-mail jest wysyłana do wybranych użytkowników.',
        },
        technologies: {
            application: ['Java', 'Apache NetBeans IDE', 'Swing', 'JDBC', 'PostgreSQL', 'Javax Mail'],
        },
        os: ['Windows', 'Linux'],
        languages: ['US'],
        image: databaseApp,
        links: {
            github: 'https://github.com/ernestbies/databaseApp',
        },
    },
    {
        name: 'Database Analyzer',
        type: {
            en: 'Desktop application',
            pl: 'Aplikacja desktopowa',
        },
        shortDescription: {
            en: 'Application for analyzing database performance while using VIEW, MATERIALIZED VIEW, WITH and TEMPORARY TABLE. The application allows you to connect to any PostgreSQL database using the JDBC engine.',
            pl: 'Aplikacja do analizy wydajności bazy danych podczas korzystania z widoku (VIEW), widoku zmaterializowanego (MATERIALIZED VIEW), WITH oraz tabeli tymczasowej (TEMPORARY TABLE). Aplikacja umożliwia połączenie się z dowolną bazą danych PostgreSQL za pomocą silnika JDBC.',
        },
        description: {
            en: 'Application for analyzing database performance while using VIEW, MATERIALIZED VIEW, WITH and TEMPORARY TABLE. The application allows you to connect to any PostgreSQL database using the JDBC engine. The login data can be saved and read because the application uses serialization.',
            pl: 'Aplikacja do analizy wydajności bazy danych podczas korzystania z widoku (VIEW), widoku zmaterializowanego (MATERIALIZED VIEW), WITH oraz tabeli tymczasowej (TEMPORARY TABLE). Aplikacja umożliwia połączenie się z dowolną bazą danych PostgreSQL za pomocą silnika JDBC. Dane logowania można zapisywać i odczytywać, ponieważ aplikacja korzysta z serializacji.',
        },
        technologies: {
            application: ['Java', 'Apache NetBeans IDE', 'Swing', 'JDBC', 'PostgreSQL'],
        },
        os: ['Windows', 'Linux'],
        languages: ['US'],
        image: databaseAnalyzer,
        links: {
            github: 'https://github.com/ernestbies/databaseAnalyzer',
        },
    },
    {
        name: 'Task planner',
        type: {
            en: 'Internet application',
            pl: 'Aplikacja internetowa',
        },
        shortDescription: {
            en: 'My first project in Angular. Task Planner covers the basics of Angular framework.',
            pl: 'Mój pierwszy projekt z wykorzystaniem Angular. Task Planner obejmuje podstawy środowiska programistycznego Angular.',
        },
        description: {
            en: 'My first school project in Angular. Task Planner covers the basics of Angular. The application includes data binding, the basic appearance of pages using HTML and CSS, forms, tables, dialogs, downloading data using the HTTP protocol from an external server and others.',
            pl: 'Mój pierwszy projekt szkolny w Angular. Task Planner obejmuje podstawy Angulara. Aplikacja obejmuje wiązanie danych, podstawowy wygląd stron z wykorzystaniem HTML i CSS, formularze, tabele, dialogi, pobieranie danych za pomocą protokołu HTTP z zewnętrznego serwera i inne.',
        },
        technologies: {
            application: ['Angular', 'TypeScript', 'HTML', 'CSS'],
        },
        os: ['Internet application'],
        languages: ['PL', 'US'],
        image: taskPlanner,
        links: {
            github: 'https://github.com/ernestbies/task_planner',
        },
    },
    {
        name: 'Java Good Practices',
        type: {
            en: 'Desktop application',
            pl: 'Aplikacja desktopowa',
        },
        shortDescription: {
            en: 'Project covering issues such as good Java programming practices, code style, code review, functional programming and application testing.',
            pl: 'Projekt obejmujący zagadnienia takie jak dobre praktyki programowania w Java, code style, code review, programowanie funkcyjne oraz testowanie aplikacji.',
        },
        description: {
            en: 'Project on good Java programming practices, code style, code review and testing. It includes a program that checks the correctness of moves on the board using unit tests. The project uses the Java programming language, the Spring platform and the JUnit library. It also includes a program that validates sample basic banking operations with unit testing using the Spock library and the Groovy programming language. The project also covers functional programming issues such as the use of streams, maps and filters.',
            pl: "Projekt na temat dobrych praktyk programowania w Java, code style'u, code review oraz testowaniu. Zawiera program sprawdzający poprawność ruchów na szachownicy za pomocą testów jednostkowych. W projekcie wykorzystano język programowania Java, platformę Spring oraz bibliotekę JUnit. Zawiera również program, który weryfikuje przykładowe podstawowe operacje bankowe za pomocą testów jednostkowych z wykorzystaniem biblioteki Spock i języka programowania Groovy. Projekt obejmuje także zagadanienia związane z programowaniem funkcyjnym, takie jak wykorzystanie strumieni, map i filtrów.",
        },
        technologies: {
            application: ['Java', 'IntelliJ IDEA', 'Spring Framework', 'JUnit', 'Groovy', 'Spock'],
        },
        os: ['Windows', 'Linux'],
        languages: ['PL', 'US'],
        image: javaGoodPractices,
        links: {
            github: 'https://github.com/ernestbies/Java-good-practices',
        },
    },
    {
        name: 'Algen',
        type: {
            en: 'Desktop application',
            pl: 'Aplikacja desktopowa',
        },
        shortDescription: {
            en: 'A program that implements genetic algorithms in the C language. It was my first project using the C programming language.',
            pl: 'Program zawierający implementację algorytmów genetycznych w języku C. Był to mój pierwszy projekt z wykorzystaniem języka programowania C.',
        },
        description: {
            en: 'A program that implements genetic algorithms in the C language. It was my first project using the C language.',
            pl: 'Program zawierający implementację algorytmów genetycznych w języku C. Był to mój pierwszy projekt z wykorzystaniem języka C.',
        },
        technologies: {
            application: ['C', 'Code::Blocks'],
        },
        os: ['Windows', 'Linux'],
        languages: ['PL'],
        image: algen,
        links: {
            github: 'https://github.com/ernestbies/algen',
        },
    },
    {
        name: 'inspyred',
        type: {
            en: 'Desktop application',
            pl: 'Aplikacja desktopowa',
        },
        shortDescription: {
            en: 'Lecture and laboratory with programs and examples on bio-inspired computational intelligence algorithms using the Python programming language and the inspyred library.',
            pl: 'Wykład oraz laboratorium wraz z programami i przykładami dotyczący algorytmów inspirowanych biologią z wykorzystaniem języka programowania Python oraz biblioteki inspyred.',
        },
        description: {
            en: 'Lecture and laboratory with programs and examples on bio-inspired computational intelligence algorithms using the Python programming language and the inspyred library.',
            pl: 'Wykład oraz laboratorium wraz z programami i przykładami dotyczący algorytmów inspirowanych biologią z wykorzystaniem języka programowania Python oraz biblioteki inspyred.',
        },
        technologies: {
            application: ['Python', 'inspyred', 'IDLE'],
        },
        os: ['Windows', 'Linux'],
        languages: ['PL', 'US'],
        image: inspyred,
        links: {
            github: 'https://github.com/ernestbies/inspyred',
        },
    },
    {
        name: 'Pizzeria database',
        type: {
            en: 'Database project',
            pl: 'Projekt bazy danych',
        },
        shortDescription: {
            en: 'Pizzeria database created with Toad Data Modeler. Basic SQL components (functions, triggers, etc.) have been implemented in the project.',
            pl: 'Projekt bazy danych pizzerii zaprojektowanaej za pomocą Toad Data Modeler. W projekcie zaimplementowano podstawowe komponenty SQL (funkcje, wyzwalacze itp.).',
        },
        description: {
            en: 'Pizzeria database created with Toad Data Modeler. Basic SQL components (functions, triggers, etc.) have been implemented in the project. The main goal of the project was to create a database for a pizzeria that allows the customer to place orders for the selected type of pizza along with extras, as well as after agreeing to register in the pizzeria database, getting appropriate discounts. Placing an order is possible via the Internet, by phone or in person. After confirming the payment, the preparation of the pizza is transferred for implementation.',
            pl: 'Baza danych pizzerii utworzona za pomocą Toad Data Modeler. W projekcie zaimplementowano podstawowe komponenty SQL (funkcje, wyzwalacze itp.). Głównym celem projektu było stworzenie bazy danych dla pizzerii umożliwiającej klientowi składanie zamówień na wybrany rodzaj pizzy wraz z dodatkami, a także po wyrażeniu zgody na rejestrację w bazie pizzerii, uzyskanie odpowiednich rabatów. Złożenie zamówienia możliwe jest przez internet, telefon lub osobiście. Po potwierdzeniu wpłaty przygotowanie pizzy zostaje przekazane do realizacji.',
        },
        technologies: {
            project: ['Toad Data Modeler', 'SQL', 'PostgreSQL'],
        },
        os: ['Windows', 'Linux'],
        languages: ['PL', 'US'],
        image: pizzeriaDatabase,
        links: {
            github: 'https://github.com/ernestbies/pizzeria-database',
        },
    },
    {
        name: 'Car service station',
        type: {
            en: 'BPMN Model',
            pl: 'Model BPMN',
        },
        shortDescription: {
            en: 'Project implemented as part of POWER Project. The subject of the project was to describe and model processes using Business Process Modeling Notation (BPMN) showing the operation of car service station.',
            pl: 'Projekt realizowany w ramach projektu POWER. Przedmiotem projektu było opisanie i zamodelowanie procesów z wykorzystaniem notacji modelowania procesów biznesowych (BPMN) obrazujących działanie stacji obsługi samochodów.',
        },
        description: {
            en: 'Project implemented as part of POWER Project (Projekt Współpraca Synergia Zarządzanie w Tarnowie). The subject of the project was to describe and model processes using Business Process Modeling Notation (BPMN) showing the operation of car service station. A detailed course of the repair process of the damaged vehicle will be presented in this project, starting from delivering damaged car for repair to handing over the vehicle. A description of the car engine repair, spare parts ordering, car handover and payment process is presented.',
            pl: 'Projekt realizowany w ramach projektu POWER (Projekt Współpraca Synergia Zarządzanie w Tarnowie). Przedmiotem projektu było opisanie i zamodelowanie procesów z wykorzystaniem notacji modelowania procesów biznesowych (BPMN) obrazujących działanie stacji obsługi samochodów. W ramach tego projektu zostanie przedstawiony szczegółowy przebieg procesu naprawy uszkodzonego pojazdu, począwszy od dostarczenia uszkodzonego samochodu do naprawy, a skończywszy na przekazaniu pojazdu. Przedstawiono opis procesu naprawy silnika samochodu, zamawiania części zamiennych, odbioru samochodu oraz procesu płatności.',
        },
        technologies: {
            project: ['Signavio Process Manager - Academic', 'Business Process Model and Notation (BPMN)'],
        },
        os: ['Windows', 'Linux'],
        languages: ['PL', 'US'],
        image: carServiceStation,
        links: {
            github: 'https://github.com/ernestbies/Car-service-station-BPMN',
        },
    },
];

export const competitionsProjects = [
    {
        name: 'iOS Device Data Extractor',
        description: {
            en: 'iOS Device Data Extractor is an Autopsy module that creates an encrypted or non-encrypted iOS backup of iPhone and iPad, currently running iOS 10.2 and above. It extracts files from an encrypted or unencrypted iOS backup, including a backup made with iTunes, and can compress them into a ZIP archive for use with the iLEAPP module, for example.',
            pl: "iOS Device Data Extractor to moduł Autopsy, który tworzy zaszyfrowaną lub nieszyfrowaną kopię zapasową iPhone'a i iPada z systemem iOS 10.2 lub nowszym. Wyodrębnia pliki z zaszyfrowanej lub niezaszyfrowanej kopii zapasowej systemu iOS, w tym kopii zapasowej utworzonej za pomocą iTunes, może ją skompresować do archiwum ZIP do użytku na przykład z modułem iLEAPP.",
        },
        technologies: [
            {
                name: 'Windows OS',
            },
            {
                name: 'Linux (Ubuntu 20.04 OS)',
            },
            {
                name: 'Autopsy (ver. 4.19.1)',
                logo: AutopsyLogo,
            },
            {
                name: 'Java',
                logo: JavaLogo,
            },
            {
                name: 'Apache NetBeans IDE (ver. 12.2)',
                logo: NetBeansLogo,
            },
        ],
        image: iOSDeviceDataExtractor,
        links: [
            {
                name: 'Organizer',
                url: 'https://www.osdfcon.org/2021-event/2021-module-development-contest',
                image: OSDFConLogoSmall,
            },
            {
                name: 'Github',
                url: 'https://github.com/ernestbies/iOSDeviceDataExtractor',
            },
            {
                name: 'Youtube',
                url: 'https://www.youtube.com/watch?v=CrBmTBpnK5c',
            },
        ],
        competition: {
            name: 'Open Source Digital Forensics Conference (Virtual OSDFCon 2021)',
            image: OSDFConLogoLarge,
            text: {
                pl: 'Projekt zdobył pierwsze miejsce w konkursie oraz został umieszczony na stronie organizatora.',
                en: "The project won first place in the competition and was placed on the organizer's website. ",
            },
        },
    },
    {
        name: 'Camera Fingerprint PRNU',
        description: {
            en: 'Camera Fingerprint PRNU uses photo response non-uniformity of camera sensor (PRNU) to check if a photo was truly taken by a suspected camera or not. Module is not based on metadata such as EXIF, performs a physical analysis of the camera sensor, determines photo response non-uniformity (PRNU), uses the principle that each sensor pixel behaves differently with effects such as non-uniform photo response, making each sensor is unique, identifies each pixel anomaly and uses this information to create a description of the camera sensor - the camera fingerprint.',
            pl: 'Camera Fingerprint PRNU wykorzystuje niejednorodność odpowiedzi na zdjęcia czujnika aparatu (PRNU), aby sprawdzić, czy zdjęcie zostało naprawdę zrobione przez podejrzany aparat, czy nie. Moduł nie bazuje na metadanych typu EXIF, wykonuje fizyczną analizę czujnika aparatu, określa niejednorodność odpowiedzi fotoelektrycznej (PRNU), wykorzystuje zasadę, że każdy piksel czujnika zachowuje się inaczej z efektami takimi jak niejednorodność fotoodpowiedzi, dzięki czemu każdy czujnik jest unikalny, identyfikuje każdą anomalię piksela i wykorzystuje te informacje do stworzenia opisu czujnika aparatu – odcisku palca aparatu.',
        },
        technologies: [
            {
                name: 'Windows OS',
            },
            {
                name: 'Autopsy (ver. 4.15.0)',
                logo: AutopsyLogo,
            },
            {
                name: 'Python (ver. 3.6.0)',
                logo: PythonLogo,
            },
            {
                name: 'Apache NetBeans IDE (ver. 9.0)',
                logo: NetBeansLogo,
            },
        ],
        image: cameraFingerprint,
        links: [
            {
                name: 'Organizer',
                url: 'https://www.osdfcon.org/2020-event/2020-module-development-contest',
                image: OSDFConLogoSmall,
            },
            {
                name: 'Github',
                url: 'https://github.com/ernestbies/Camera-Fingerprint-PRNU',
            },
            {
                name: 'Youtube',
                url: 'https://www.youtube.com/watch?v=lsoqVuuq9T0',
            },
        ],
        competition: {
            name: 'Open Source Digital Forensics Conference (OSDFCon 2020)',
            image: OSDFConLogoLarge,
            text: {
                pl: 'Projekt został zaprezentowany na konferencji oraz umieszczony na stronie organizatora.',
                en: "The project was presented at the conference and posted on the organizer's website.",
            },
        },
    },
];
