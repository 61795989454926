import styled, { css } from 'styled-components';
import wallpaperBackground from '../../../assets/images/wallpapers/wallpaper3.png';

export const ProgrammingDiv = styled.div`
    width: 100%;
    height: 100%;
    min-height: 450px;
    background-color: white;
    position: relative;
    display: flex;
    overflow: hidden;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        flex-direction: column;
        min-height: 1000px;
    }

    ${({ theme }) => theme.mediaBreakpoints.md} {
        min-height: 800px;
    }

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        min-height: 700px;
    }
`;

export const ProgrammingImage = styled.img`
    flex: 1;
    width: 100%;
    max-width: 51%;
    object-fit: cover;
    transform: translateX(-1000px);
    transition: all 2s ease-in-out;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        flex: 0 1 auto;
        max-height: auto;
        max-width: 100%;
    }

    ${({ inView }) =>
        inView &&
        css`
            transform: translateX(0);
        `};
`;

export const ProgrammingInfo = styled.div`
    background-color: white;
    flex: 1;
    position: relative;
    transform: translateX(1000px);
    transition: all 2s ease-in-out;

    ${({ inView }) =>
        inView &&
        css`
            transform: translateX(0);
        `};
`;

export const ProgrammingBackgroundImage = styled.div`
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-image: url(${wallpaperBackground});
    position: absolute;
    opacity: 0.2;
`;

export const ProgrammingBackgroundColor = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.secondary + 99};
`;

export const InfoBox = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.secondary + 70};
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const BoxTitle = styled.p`
    font-size: 30px;
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: white;
    text-align: center;
    width: 80%;

    &:after {
        content: ' </>';
    }
`;

export const BoxText = styled.p`
    font-size: ${({ theme }) => theme.font.size.md};
    font-family: ${({ theme }) => theme.font.family.Roboto};
    color: white;
    width: 70%;
    text-align: center;
`;

export const CleanCodeSection = styled.div`
    display: flex;
`;

export const CleanCodeBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px;

    @media (max-width: 400px) {
        margin-left: 20px;
        margin-right: 20px;
    }

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        transform: scale(0.8);
        margin-left: 5px;
        margin-right: 5px;
    }
`;

export const StyledCheckbox = styled.input`
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 1px;
    display: grid;
    place-content: center;

    &:before {
        content: '';
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em black;
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked:before {
        transform: scale(1);
    }
`;

export const CleanCodeText = styled.p`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 15px;
    letter-spacing: 2px;
    margin-left: 10px;
`;

export const Background = styled.div``;
