import React from 'react';
import { FooterIcons, FooterLink, FooterNav, FooterText, StyledFooter } from './Footer.styles';
import { useTranslation } from 'react-i18next';
import ContactIcon from '../../components/ContactIcon/ContactIcon';
import { WEBSITE_URL } from '../../utils/data/information';

const Footer = () => {
    const { t } = useTranslation('common');

    return (
        <StyledFooter>
            <FooterNav>
                <FooterLink href={'/#'}>{t('home')}</FooterLink>
                <FooterLink href={'/#projects'}>{t('projects')}</FooterLink>
                <FooterLink href={'/#skills'}>{t('skills')}</FooterLink>
                <FooterLink href={'/#hobby'}>{t('hobby')}</FooterLink>
                <FooterLink href={'/#contact'}>{t('contact')}</FooterLink>
            </FooterNav>
            <FooterIcons>
                <ContactIcon type={'email'} width={30} height={30} />
                <ContactIcon type={'github'} width={30} height={30} />
                <ContactIcon type={'discord'} width={30} height={30} itemId={'discord-small'} />
                <ContactIcon type={'linkedin'} width={30} height={30} />
            </FooterIcons>
            <FooterText>
                &copy; 2021 - {new Date().getFullYear()} {WEBSITE_URL}. {t('all_rights_reserved')}
            </FooterText>
        </StyledFooter>
    );
};

export default Footer;
