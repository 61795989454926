import styled, { css } from 'styled-components';

export const LogoContainer = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    ${({ size }) =>
        size === 'small' &&
        css`
            display: none;
        `};

    ${({ theme, resizable }) =>
        resizable &&
        css`
            ${theme.mediaBreakpoints.mobile} {
                display: none;
            }
        `};
`;

export const Img = styled.img`
    width: 40px;
    height: 40px;
`;

export const WebsiteTitle = styled.p`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 18px;
    letter-spacing: 1px;
    margin: 0;
    color: black;
`;

export const WebsiteSubtitle = styled.p`
    font-family: ${({ theme }) => theme.font.family.Helvetica};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 11px;
    margin: 1px 0 0 0;
    color: #333;
`;
