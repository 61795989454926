import styled, { css, keyframes } from 'styled-components';

const revealItemLeftRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(-40px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const revealItemRightLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(40px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const revealImage = keyframes`
    from {
        opacity: 0;
        transform: translateY(40px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const StyledContactContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const ContactLine = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const StyledContactText = styled.p`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: 40px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-transform: uppercase;
    text-align: left;
    white-space: pre-wrap;

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        width: 90%;
        font-size: 36px;
        text-align: center;
    }
`;

export const StyledContactSubtext = styled.span`
    color: #888;
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-top: 5px;

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        font-size: ${({ theme }) => theme.font.size.lg};
    }
`;

export const ContactItemContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;

    ${({ align, inView }) =>
        inView &&
        css`
            animation: ${align === 'right' ? revealItemRightLeft : revealItemLeftRight} 2s ease-in-out
                forwards;
        `}
`;

export const ContactImgContainer = styled.div`
    flex: 1;
    opacity: 0;

    ${({ inView }) =>
        inView &&
        css`
            animation: ${revealImage} 2s ease-in-out forwards;
        `}
`;

export const ContactIconsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const ContactProjectsOverview = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
    max-height: 500px;
`;
