import styled, { css, keyframes } from 'styled-components';

const revealContainer = keyframes`
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const CompetitionContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    opacity: 0;
    margin-top: 80px;

    ${({ inView }) =>
        inView &&
        css`
            animation: ${revealContainer} 2s ease-in-out forwards;
            animation-delay: 1s;
        `};
`;
