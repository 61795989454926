// Languages
import JavaScriptLogo from './../../assets/images/languages/javascript.png';
import JQueryLogo from './../../assets/images/languages/jquery.png';
import HtmlLogo from './../../assets/images/languages/html.png';
import CssLogo from './../../assets/images/languages/css.png';
import JavaLogo from './../../assets/images/languages/java.png';
import KotlinLogo from './../../assets/images/languages/kotlin.png';
import GroovyLogo from './../../assets/images/languages/groovy.png';
import CLogo from './../../assets/images/languages/c.png';
import CPlusPlusLogo from './../../assets/images/languages/c-plus-plus.png';
import CSharpLogo from './../../assets/images/languages/c-sharp.png';
import PythonLogo from './../../assets/images/languages/python.png';
import RLogo from './../../assets/images/languages/r.png';
import HaskellLogo from './../../assets/images/languages/haskell.png';
import SqlLogo from './../../assets/images/languages/sql.png';
import PlPgSqlLogo from './../../assets/images/languages/plpgsql.png';

// Technologies
import ReactLogo from './../../assets/images/technologies/reactjs.png';
import ReactNativeLogo from './../../assets/images/technologies/reactnative.png';
import GatsbyLogo from './../../assets/images/technologies/gatsby.png';
import AngularLogo from './../../assets/images/technologies/angular.png';
import NextjsLogo from './../../assets/images/technologies/nextjs.png';
import ViteLogo from './../../assets/images/technologies/vite.png';
import NodeLogo from './../../assets/images/technologies/nodejs.png';
import HapiLogo from './../../assets/images/technologies/hapijs.png';
import ExpressLogo from './../../assets/images/technologies/express.png';
import JwtLogo from './../../assets/images/technologies/jwt.png';
import SpringLogo from './../../assets/images/technologies/spring.png';
import UnityLogo from './../../assets/images/technologies/unity.png';
import UmlLogo from './../../assets/images/technologies/uml.png';
import BpmnLogo from './../../assets/images/technologies/bpmn.png';
import PostgresqlLogo from './../../assets/images/technologies/postgresql.png';
import MariadbLogo from './../../assets/images/technologies/mariadb.png';
import SqliteLogo from './../../assets/images/technologies/sqlite.png';
import MongodbLogo from './../../assets/images/technologies/mongodb.png';
import MongooseLogo from './../../assets/images/technologies/mongoose.png';
import JestLogo from './../../assets/images/technologies/jest.png';
import JunitLogo from './../../assets/images/technologies/junit.png';
import SpockLogo from './../../assets/images/technologies/spock.png';
import GitLogo from './../../assets/images/technologies/git.png';

// Cloud Application Platform
import AscLogo from './../../assets/images/technologies/asc.png';
import GpcLogo from './../../assets/images/technologies/gpc.png';
import HerokuLogo from './../../assets/images/technologies/heroku.png';
import NetlifyLogo from './../../assets/images/technologies/netlify.png';
import RenderLogo from './../../assets/images/technologies/render.png';
import RailwayLogo from './../../assets/images/technologies/railway.png';

// Dev environments
import IntellijLogo from './../../assets/images/technologies/intellij.png';
import NetbeansLogo from './../../assets/images/technologies/netbeans.png';
import QtLogo from './../../assets/images/technologies/qt.png';
import CodeBlocksLogo from './../../assets/images/technologies/codeblocks.png';
import VscLogo from './../../assets/images/technologies/vsc.png';

// Databases
import PgadminLogo from './../../assets/images/technologies/pgadmin.png';
import AdminerLogo from './../../assets/images/technologies/adminer.png';
import MongodbCompassLogo from './../../assets/images/technologies/mongodbcompass.png';

export const skills = {
    frontend: {
        languages: [
            {
                name: 'JavaScript',
                icon: JavaScriptLogo,
            },
            {
                name: 'HTML',
                icon: HtmlLogo,
            },
            {
                name: 'CSS',
                icon: CssLogo,
            },
            {
                name: 'jQuery',
                icon: JQueryLogo,
            },
        ],
        technologies: [
            {
                name: 'React.js',
                icon: ReactLogo,
            },
            {
                name: 'React Native',
                icon: ReactNativeLogo,
            },
            {
                name: 'Gatsby',
                icon: GatsbyLogo,
            },
            {
                name: 'Angular',
                icon: AngularLogo,
            },
            {
                name: 'Next.js',
                icon: NextjsLogo,
            },
            {
                name: 'Vite',
                icon: ViteLogo,
            },
        ],
        env: [
            {
                name: 'Visual Studio Code',
                icon: VscLogo,
            },
            {
                name: 'IntelliJ IDEA',
                icon: IntellijLogo,
            },
        ],
    },
    backend: {
        languages: [
            {
                name: 'JavaScript',
                icon: JavaScriptLogo,
            },
            {
                name: 'Java',
                icon: JavaLogo,
            },
            {
                name: 'Kotlin',
                icon: KotlinLogo,
            },
            {
                name: 'C',
                icon: CLogo,
            },
            {
                name: 'C++',
                icon: CPlusPlusLogo,
            },
            {
                name: 'C#',
                icon: CSharpLogo,
            },
            {
                name: 'Python',
                icon: PythonLogo,
            },
            {
                name: 'R',
                icon: RLogo,
            },
            {
                name: 'Haskell',
                icon: HaskellLogo,
            },
        ],
        technologies: [
            {
                name: 'Node.js',
                icon: NodeLogo,
            },
            {
                name: 'Hapi.js',
                icon: HapiLogo,
            },
            {
                name: 'Express.js',
                icon: ExpressLogo,
            },
            {
                name: 'Spring',
                icon: SpringLogo,
            },
            {
                name: 'JWT',
                icon: JwtLogo,
            },
            {
                name: 'Unity',
                icon: UnityLogo,
            },
            {
                name: 'UML (Unified Modeling Language)',
                icon: UmlLogo,
            },
            {
                name: 'BPMN 2.0 (Business Process Model and Notation)',
                icon: BpmnLogo,
            },
        ],
        env: [
            {
                name: 'IntelliJ IDEA',
                icon: IntellijLogo,
            },
            {
                name: 'NetBeans IDE',
                icon: NetbeansLogo,
            },
            {
                name: 'Qt',
                icon: QtLogo,
            },
            {
                name: 'Code::Blocks',
                icon: CodeBlocksLogo,
            },
            {
                name: 'Visual Studio Code',
                icon: VscLogo,
            },
        ],
    },
    databases: {
        languages: [
            {
                name: 'SQL',
                icon: SqlLogo,
            },
            {
                name: 'PL/pgSQL',
                icon: PlPgSqlLogo,
            },
        ],
        technologies: [
            {
                name: 'PostgreSQL',
                icon: PostgresqlLogo,
            },
            {
                name: 'MariaDB',
                icon: MariadbLogo,
            },
            {
                name: 'MongoDB',
                icon: MongodbLogo,
            },
            {
                name: 'SQLite',
                icon: SqliteLogo,
            },
            {
                name: 'mongoose',
                icon: MongooseLogo,
            },
        ],
        env: [
            {
                name: 'pgAdmin',
                icon: PgadminLogo,
            },
            {
                name: 'MongoDB Compass',
                icon: MongodbCompassLogo,
            },
            {
                name: 'adminer',
                icon: AdminerLogo,
            },
        ],
    },
    testing: {
        languages: [
            {
                name: 'JavaScript',
                icon: JavaScriptLogo,
            },
            {
                name: 'Java',
                icon: JavaLogo,
            },
            {
                name: 'Groovy',
                icon: GroovyLogo,
            },
        ],
        technologies: [
            {
                name: 'Jest',
                icon: JestLogo,
            },
            {
                name: 'JUnit',
                icon: JunitLogo,
            },
            {
                name: 'Spock',
                icon: SpockLogo,
            },
            {
                name: 'Git (GitHub / Bitbucket / GitLab)',
                icon: GitLogo,
            },
        ],
        env: [
            {
                name: 'Google Play Console (Android Apps)',
                icon: GpcLogo,
            },
            {
                name: 'Apple App Store Connect (iOS Apps)',
                icon: AscLogo,
            },
            {
                name: 'Heroku (Cloud Application Platform)',
                icon: HerokuLogo,
            },
            {
                name: 'Netlify (Cloud Application Platform)',
                icon: NetlifyLogo,
            },
            {
                name: 'Render (Cloud Application Platform)',
                icon: RenderLogo,
            },
            {
                name: 'Railway (Cloud Application Platform)',
                icon: RailwayLogo,
            },
        ],
    },
};
