import React from 'react';
import { useInView } from 'react-intersection-observer';
import Section from '../../components/Section/Section';
import { useTranslation } from 'react-i18next';
import Slider from '../../components/Slider/Slider';
import { hobby } from '../../utils/data/hobby';
import playAnimations from '../../utils/helpers/playAnimations';
import useMobileWidth from '../../hooks/useMobileWidth';

const Hobby = () => {
    const { t } = useTranslation('common');
    const isMobile = useMobileWidth();

    const { ref, inView } = useInView({
        threshold: 0.45,
        triggerOnce: true,
    });

    return (
        <div ref={ref} id={'hobby'}>
            <Section
                backgroundColor={'#FFFFFF'}
                inView={playAnimations(inView, isMobile)}
                subtitle={t('hobby')}
                title={t('interests')}
                text={t('hobby_text')}
                style={{ paddingBottom: 0 }}>
                <Slider
                    slides={hobby}
                    customWidth={'100%'}
                    sliderType={'hobby'}
                    inView={playAnimations(inView, isMobile)}
                />
            </Section>
        </div>
    );
};

export default Hobby;
