import React from 'react';
import { IconWrapper, LineDivider, LinkText, StyledDrawer, StyledLink } from './Drawer.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faDesktop, faEnvelope, faHome, faStream } from '@fortawesome/free-solid-svg-icons';
import './Drawer.styles.css';
import { connect } from 'react-redux';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { manageDrawer } from '../../actions/drawerActions';

const Drawer = ({ drawerIsOpen, manageDrawer }) => {
    const { t } = useTranslation('common');

    const iconStyle = {
        width: 25,
        height: 25,
        float: 'right',
        marginRight: 10,
    };

    return (
        <StyledDrawer
            className={drawerIsOpen === true ? 'slider-in' : drawerIsOpen === false ? 'slider-out' : ''}>
            <StyledLink href={'/#'} style={{ marginTop: 15 }} onClick={() => manageDrawer(false)}>
                <IconWrapper>
                    <FontAwesomeIcon icon={faHome} color={'grey'} style={iconStyle} />
                </IconWrapper>
                <LineDivider>{'//'}</LineDivider>
                <LinkText>{t('home')}</LinkText>
            </StyledLink>
            <StyledLink href={'/#projects'} onClick={() => manageDrawer(false)}>
                <IconWrapper>
                    <FontAwesomeIcon icon={faCode} color={'grey'} style={iconStyle} />
                </IconWrapper>
                <LineDivider>{'//'}</LineDivider>
                <LinkText>{t('projects')}</LinkText>
            </StyledLink>
            <StyledLink href={'/#skills'} onClick={() => manageDrawer(false)}>
                <IconWrapper>
                    <FontAwesomeIcon icon={faStream} color={'grey'} style={iconStyle} />
                </IconWrapper>
                <LineDivider>{'//'}</LineDivider>
                <LinkText>{t('skills')}</LinkText>
            </StyledLink>
            <StyledLink href={'/#hobby'} onClick={() => manageDrawer(false)}>
                <IconWrapper>
                    <FontAwesomeIcon icon={faDesktop} color={'grey'} style={iconStyle} />
                </IconWrapper>
                <LineDivider>{'//'}</LineDivider>
                <LinkText>{t('hobby')}</LinkText>
            </StyledLink>
            <StyledLink href={'/#contact'} onClick={() => manageDrawer(false)}>
                <IconWrapper>
                    <FontAwesomeIcon icon={faEnvelope} color={'grey'} style={iconStyle} />
                </IconWrapper>
                <LineDivider>{'//'}</LineDivider>
                <LinkText>{t('contact')}</LinkText>
            </StyledLink>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <LanguageSelector style={{ margin: 30 }} />
            </div>
        </StyledDrawer>
    );
};

const mapStateToProps = ({ drawerReducer }) => drawerReducer;
const mapDispatchToProps = { manageDrawer };

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
