import styled, { css } from 'styled-components';

export const StyledArrow = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    justify-content: center;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    transition: all 1s ease-in-out;
    background-color: transparent;

    ${({ direction }) =>
        direction === 'right'
            ? css`
                  right: 25px;
              `
            : css`
                  left: 25px;
              `};

    &:hover {
        background-color: #ffffff10;
        transform: scale(1.1);
    }

    ${({ theme }) => theme.mediaBreakpoints.md} {
        display: none;
    }
`;

export const StyledArrowImg = styled.img`
    width: 45px;
    height: 45px;
    transform: translateX(${({ direction }) => (direction === 'left' ? '-3' : '1')}px);

    &:focus {
        outline: 0;
    }
`;
