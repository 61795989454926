import styled from 'styled-components';

const StyledSliderContent = styled.div`
    transform: translateX(-${({ translate }) => translate}px);
    transition: transform ${({ transition }) => transition}s ease-out;
    height: 100%;
    width: ${({ width }) => width}px;
    display: flex;
    background: linear-gradient(#000000, #1f1f1f);
`;

export default StyledSliderContent;
