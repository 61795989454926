import styled from 'styled-components';

export const DiscordIconWrapper = styled.div`
    width: ${({ width }) => width ?? 75}px;
    height: ${({ height }) => height ?? 75}px;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #696969;
    background-image: linear-gradient(#5c64f3, #5c64f3);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: all 1s ease-in-out;
    cursor: pointer;

    &:hover {
        background-size: 100% 100%;
    }

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        transform: scale(0.8);
        margin: 0;
    }
`;
