import React from 'react';
import { useInView } from 'react-intersection-observer';
import Section from '../../components/Section/Section';
import { useTranslation } from 'react-i18next';
import { SkillsContainer } from './Skills.styles';
import SkillsItem from '../../components/SkillsItem/SkillsItem';
import playAnimations from '../../utils/helpers/playAnimations';
import useMobileWidth from '../../hooks/useMobileWidth';

const Skills = () => {
    const { t } = useTranslation('common');
    const isMobileWidth = useMobileWidth();

    const { ref, inView } = useInView({
        threshold: 0.45,
        triggerOnce: true,
    });

    return (
        <div ref={ref} id={'skills'}>
            <Section
                backgroundColor={'#f5f5f5'}
                inView={playAnimations(inView, isMobileWidth)}
                subtitle={t('skills')}
                title={t('knowledge')}
                text={t('skills_text')}>
                <SkillsContainer>
                    <SkillsItem animate={playAnimations(inView, isMobileWidth)} type={'frontend'} />
                    <SkillsItem animate={playAnimations(inView, isMobileWidth)} type={'backend'} />
                    <SkillsItem animate={playAnimations(inView, isMobileWidth)} type={'databases'} />
                    <SkillsItem animate={playAnimations(inView, isMobileWidth)} type={'testing'} />
                </SkillsContainer>
            </Section>
        </div>
    );
};

export default Skills;
