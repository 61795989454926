import React from 'react';
import { StyledArrow, StyledArrowImg } from './Arrow.styles';
import leftArrow from '../../../assets/images/left-arrow.svg';
import rightArrow from '../../../assets/images/right-arrow.svg';

const Arrow = ({ direction, onClick }) => {
    return (
        <StyledArrow direction={direction} onClick={onClick}>
            <StyledArrowImg direction={direction} src={direction === 'left' ? leftArrow : rightArrow} />
        </StyledArrow>
    );
};

export default Arrow;
