import { MainButton } from './StyledButton.styles';
import React from 'react';

const StyledButton = ({ link, title, color, onClick }) => (
    <MainButton href={link} color={color} onClick={onClick}>
        {title}
    </MainButton>
);

export default StyledButton;
