import styled, { css, keyframes } from 'styled-components';

const revealContainer = keyframes`
    from {
        opacity: 0;
        transform: translateY(-70px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const StyledSlider = styled.div`
    position: relative;
    height: 500px;
    width: ${({ width }) => width}px;
    margin-top: 80px;
    overflow: hidden;
    align-self: center;
    opacity: 0;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        height: 900px;
        width: 100%;
    }

    ${({ type }) =>
        type === 'hobby' &&
        css`
            ${({ theme }) => theme.mediaBreakpoints.sm} {
                height: 650px;
            }
        `}

    ${({ inView }) =>
        inView &&
        css`
            animation: ${revealContainer} 2s ease-in-out forwards;
            animation-delay: 1s;
        `};
`;
