import styled, { css, keyframes } from 'styled-components';
import firstWave from '../../assets/images/wave1.svg';
import secondWave from '../../assets/images/wave2.svg';

const revealSectionText = keyframes`
    from {
        opacity: 0;
        transform: translateX(-30px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const StyledSection = styled.div`
    background-color: ${({ backgroundColor }) => backgroundColor};
    display: flex;
    text-align: center;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow: hidden;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        padding-bottom: 40px;
        padding-bottom: 40px;

        ${({ mobilePadding }) =>
            mobilePadding === false &&
            css`
                padding-bottom: 0;
            `}
    }

    ${({ waves }) =>
        waves &&
        css`
            background-image: url(${waves === 1 ? firstWave : secondWave});
            background-attachment: fixed;
            background-size: cover;
        `}
`;

export const SectionTitle = styled.p`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: 50px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: black;
    margin-top: 0;
    margin-bottom: 0;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        font-size: 36px;
    }
`;

export const SectionSubtitle = styled.p`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: ${({ theme }) => theme.font.size.sm};
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #c0c0c0;
    margin: 0;
`;

export const SectionTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const SectionText = styled.p`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 18px;
    color: black;
    line-height: 1.5;
    width: 70%;
    opacity: 0;
    flex: 1;
    white-space: pre-wrap;

    ${({ inView }) =>
        inView &&
        css`
            animation: ${revealSectionText} 1s ease-in-out forwards;
        `};

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        font-size: ${({ theme }) => theme.font.size.lg};
    }
`;

export const StyledLine = styled.div`
    width: 40px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.secondary};
`;

export const LineDivider = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    margin-bottom: 30px;
`;
