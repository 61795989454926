import styled, { css, keyframes } from 'styled-components';

const growLeft = keyframes`
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
`;

export const StyledItem = styled.div`
    width: 240px;
    border-top: 1px solid ${({ theme }) => theme.colors.secondary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scaleX(0);
    transform-origin: left;
    margin: 40px;

    ${({ animate }) =>
        animate &&
        css`
            animation-delay: 0.8s;
            animation: ${growLeft} cubic-bezier(0.785, 0.135, 0.15, 0.86) 1.5s forwards;
        `}
`;

export const SkillText = styled.p`
    color: black;
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-size: ${({ theme }) => theme.font.size.lg};
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-top: 10px;

    ${({ theme }) => theme.mediaBreakpoints.md} {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const SkillSubtext = styled.p`
    color: black;
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: ${({ theme }) => theme.font.weight.light};
    margin: 0;
    margin-bottom: 30px;

    ${({ theme }) => theme.mediaBreakpoints.md} {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const SkillsElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
