import { StyledTrigger, TriggerContent } from './CollapsibleTrigger.styles';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../../themes/theme';

const CollapsibleTrigger = ({ text, open }) => {
    return (
        <TriggerContent>
            <FontAwesomeIcon
                icon={open ? faSortUp : faSortDown}
                color={theme.colors.secondary}
                style={{
                    width: 15,
                    height: 15,
                    marginTop: open ? 2 : -7,
                    marginRight: 5,
                }}
            />
            <StyledTrigger>{text}</StyledTrigger>
        </TriggerContent>
    );
};

export default CollapsibleTrigger;
