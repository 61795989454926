import styled from 'styled-components';
import languageSelector from '../../assets/images/language-selector.svg';

export const StyledSelect = styled.select`
    width: 100px;
    height: 30px;
    background-color: white;
    border: 1px solid black;
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    position: relative;
    background-image: url(${languageSelector});
    background-position: left;
    background-size: 25%;
    background-repeat: no-repeat;
    text-indent: 20px;
    text-align: center;

    @-moz-document url-prefix() {
        text-indent: 0;
        padding-left: 20px;
    }
`;

export const StyledOption = styled.option`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    position: absolute;
`;
