import styled from 'styled-components';

export const WebsiteStyledItem = styled.a`
    display: block;
    width: 364px;
    height: 204px;
    background-image: url(${({ websiteImage }) => websiteImage});
    background-size: contain;
    background-color: ${({ backgroundColor }) => backgroundColor};
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    border: 1px solid #4d4d4d;
    text-decoration: none !important;
    transition: all 1s;
    margin: 10px 0;

    &:hover {
        box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.88) inset;
        transform: scale(1.1);
        z-index: 7 !important;
    }

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        flex-direction: column;
    }

    @media (max-width: 400px) {
        width: 328px;
        height: 184px;
    }

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        width: 291px;
        height: 163px;
    }
`;

export const WebsiteHoverContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const WebsiteTitle = styled.p`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-size: 20px;
    color: white;
    margin: 15px;
    margin-top: 30px;
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: ${({ color }) => color};
    padding-left: 7px;
`;

export const WebsiteDesc = styled.p`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 15px;
    color: white;
    margin: 15px;
    text-align: left;
`;
