import styled from 'styled-components';

export const StyledDots = styled.div`
    position: absolute;
    bottom: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        width: 90%;
    }
`;

export const DotsWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const StyledDot = styled.div`
    padding: 8px;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 50%;
    background-color: ${({ theme, active }) => (active ? theme.colors.secondary : '#FFFFFF60')};
`;
