const BACKEND_URL = 'https://ernestbies-backend.onrender.com';
const API_GITHUB = 'https://api.github.com/users/ernestbies';

export const validateRecaptcha = recaptcha => {
    return fetch(`${BACKEND_URL}/api/contact`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({ recaptcha }),
    });
};

export const fetchGithubStats = () => {
    return fetch(`${API_GITHUB}`).then(res => res.json());
};
