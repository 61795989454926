import React from 'react';
import contact from '../../utils/data/contact';
import { FaGithub, FaLinkedin, FaRegEnvelope } from 'react-icons/fa';
import { DiscordIcon } from '../DiscordIcon/DiscordIcon';
import { StyledIcon } from './ContactIcon.styles';
import './ContactIcon.styles.css';

const ContactIcon = ({ type, width, height, itemId }) => {
    const Icon = () => {
        const style =
            width && height
                ? {
                      width: width - 12,
                      height: height - 12,
                  }
                : {};

        switch (type) {
            case 'email':
                return <FaRegEnvelope type={'email'} className={'icon'} style={style} />;
            case 'github':
                return <FaGithub type={'github'} className={'icon'} style={style} />;
            case 'linkedin':
                return <FaLinkedin type={'linkedin'} className={'icon'} style={style} />;
            default:
                return <></>;
        }
    };

    return type === 'discord' ? (
        <DiscordIcon width={width} height={height} itemId={itemId} />
    ) : (
        <StyledIcon
            width={width}
            height={height}
            iconType={type}
            target={'_blank'}
            rel={'noreferrer'}
            href={type === 'email' ? 'mailto:' + contact.email : contact[type]}>
            <Icon />
        </StyledIcon>
    );
};

export default ContactIcon;
