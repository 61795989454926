import React from 'react';
import { ScrollToUpFab } from './ScrollToUp.styles';
import { RiArrowUpSLine } from 'react-icons/ri';
import useScrollIndicator from '../../hooks/useScrollIndicator';

const ScrollToUp = () => {
    const scrollState = useScrollIndicator(400);

    return (
        <ScrollToUpFab
            scrollPos={scrollState}
            onClick={() => typeof window !== 'undefined' && window.scrollTo(0, 0)}>
            <RiArrowUpSLine style={{ color: 'white' }} size={20} />
        </ScrollToUpFab>
    );
};

export default ScrollToUp;
