import styled, { css } from 'styled-components';

export const StyledMenu = styled.div`
    display: none;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    &:hover {
        background-color: #ebebeb;
    }

    ${({ theme }) => theme.mediaBreakpoints.navbarChange} {
        display: flex;
    }
`;

export const MenuLine = styled.div`
    width: 18px;
    height: 2px;
    background-color: black;
    border-radius: 5px;
    margin: 1px;
    transition: transform 1s ease-in-out;

    ${({ isOpen }) =>
        isOpen &&
        css`
            &:nth-child(1) {
                transform: rotate(45deg);
                margin-top: 8px;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
            }
        `}
`;
