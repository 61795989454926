import React from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path={'/'} element={<Home />} />
            <Route path={'/not-found'} element={<NotFound />} />
            <Route path={'*'} element={<Navigate from={'/*'} to={'/not-found'} />} />
        </Routes>
    </BrowserRouter>
);

export default App;
