import { useState, useEffect } from 'react';

const useMobileWidth = () => {
    const [mobile, setMobile] = useState(false);

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 992);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            handleWindowSizeChange();
            window.addEventListener('resize', handleWindowSizeChange);

            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            };
        }
    }, []);

    return mobile;
};

export default useMobileWidth;
