import React from 'react';
import { MenuLine, StyledMenu } from './HamburgerMenu.styles';
import { manageDrawer } from '../../actions/drawerActions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HamburgerMenu = ({ drawerIsOpen, manageDrawer }) => {
    const { t } = useTranslation('common');

    return (
        <StyledMenu title={t(drawerIsOpen ? 'close' : 'open')} onClick={() => manageDrawer(!drawerIsOpen)}>
            <MenuLine isOpen={drawerIsOpen} />
            <MenuLine isOpen={drawerIsOpen} />
            <MenuLine isOpen={drawerIsOpen} />
        </StyledMenu>
    );
};

const mapStateToProps = ({ drawerReducer }) => drawerReducer;
const mapDispatchToProps = { manageDrawer };

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu);
