import styled, { keyframes } from 'styled-components';

const fadeInRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(-15px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const revealText = keyframes`
    0% { opacity: 1; }
    100% { opacity: 1; }
`;

const revealBar = keyframes`
    0% { transform-origin: center left 0px; transform: scaleX(0); }
    36% { transform: scaleX(1); transform-origin: center left 0px; }
    54% { transform: scaleX(1); transform-origin: center right 0px; }
    90% { transform: scaleX(0);  transform-origin: center right 0px; }
    100% { transform: scaleX(0);  transform-origin: center right 0px; }
`;

export const StyledIntro = styled.div`
    height: 600px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23D8D8D8' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23CBCBCB'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        flex-direction: column;
    }
`;

export const StyledImage = styled.img`
    height: 500px;
    width: 500px;
    margin: 50px;
    filter: drop-shadow(1px 5px 5px #333);
    pointer-events: none;
    flex: 1;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        width: 300px;
        height: 300px;
        margin: 0;
        margin-top: 80px;
    }

    ${({ theme }) => theme.mediaBreakpoints.md} {
        width: 250px;
        height: 250px;
    }

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        width: 200px;
        height: 200px;
    }
`;

export const TextContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 30px;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        margin: 0;
        align-items: center;
    }
`;

export const StyledTitle = styled.p`
    font-size: ${({ theme }) => theme.font.size.lg};
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    color: #9c9a9a;
    letter-spacing: 4px;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    text-transform: uppercase;
    margin: 0;
    opacity: 0;
    animation: ${fadeInRight} 2s ease-in-out forwards;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        text-align: center;
        margin-top: 30px;
        font-size: 18px;
    }

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: ${({ theme }) => theme.font.size.lg};
    }
`;

export const PageTitleWrapper = styled.p`
    position: relative;
    width: fit-content;

    &:before {
        content: '';
        background: #dc143c;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        animation: ${revealBar} 4s both;
        animation-delay: 500ms;
        z-index: 4;
    }
`;

export const PageTitle = styled.span`
    font-size: 36px;
    font-family: ${({ theme }) => theme.font.family.Roboto};
    color: black;
    letter-spacing: 2px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin: 10px 0 40px 0;
    position: relative;
    opacity: 0;
    animation: ${revealText} 1s forwards;
    animation-delay: 2s;
    width: max-content;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        text-align: center;
        margin: 5px 0 5px 0;
        font-size: 25px;
    }

    ${({ theme }) => theme.mediaBreakpoints.md} {
        font-size: ${({ theme }) => theme.font.size.lg};
    }

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: 18px;
    }
`;

export const PageText = styled.p`
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: black;
    margin: 0;
    width: 80%;
    line-height: 1.4;
    opacity: 0;
    animation: ${fadeInRight} 2s ease-in-out forwards;
    animation-delay: 1s;
    margin-top: 30px;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        font-size: ${({ theme }) => theme.font.size.lg};
    }

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        width: 90%;
    }

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;
