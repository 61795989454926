import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const StyledMenu = styled.ul`
    width: 50px;
    position: fixed;
    right: 0;
    top: 35%;
    z-index: 500;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        display: none;
    }
`;

export const StyledMenuWrapper = styled.a`
    height: 100%;
    display: none;
    flex: 2;
`;

export const MenuItem = styled.li`
    width: 50px;
    height: 50px;
    background-color: #e3e3e3;
    cursor: pointer;
    transition: all 2s;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    ${({ expandable }) =>
        expandable &&
        css`
            &:hover ${StyledMenuWrapper} {
                display: flex;
            }

            &:hover {
                width: 250px;
            }
        `};

    &:hover {
        background-color: ${({ theme }) => theme.colors.secondary};
    }
`;

export const StyledMenuLink = styled.a`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledMenuText = styled.span`
    color: white;
    font-size: ${({ theme }) => theme.font.size.md};
    font-family: ${({ theme }) => theme.font.family.Helvetica};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
`;

export const StyledColoredMenuText = styled.span`
    color: black;
    letter-spacing: 1px;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
    width: 25px;
    height: 25px;
    flex: 1;
    color: black;
`;
