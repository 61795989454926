import styled, { css, keyframes } from 'styled-components';

const revealContainer = keyframes`
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const WebsitesSectionContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    opacity: 0;

    ${({ inView }) =>
        inView &&
        css`
            animation: ${revealContainer} 2s ease-in-out forwards;
            animation-delay: 1s;
        `};

    ${({ theme }) => theme.mediaBreakpoints.xxl} {
        width: 75%;
        margin: 0 auto;
    }

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        width: 100%;
        margin-top: 40px;
        flex-direction: column;
    }
`;
