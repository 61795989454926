import {
    SectionText,
    SectionTitle,
    SectionTitleWrapper,
    SectionSubtitle,
    StyledSection,
    StyledLine,
    LineDivider,
} from './Section.styles';
import React from 'react';
import PropTypes from 'prop-types';
import ColoredText from '../ColoredText/ColoredText';
import replaceTextByHTML from '../../utils/helpers/replaceTextByHTML';
import { ImFire } from 'react-icons/im';
import { theme } from '../../themes/theme';

const Section = ({
    children,
    title,
    subtitle,
    text,
    backgroundColor,
    waves,
    inView,
    style,
    mobilePadding,
    replaceTextByHtml,
}) => {
    const { wordToReplace, link } = replaceTextByHtml ?? {};

    return (
        <StyledSection
            waves={waves}
            backgroundColor={backgroundColor}
            style={style}
            mobilePadding={mobilePadding}>
            <SectionTitleWrapper>
                <SectionSubtitle>
                    <ColoredText>{'// '}</ColoredText>
                    {subtitle}
                </SectionSubtitle>
                <SectionTitle>{title}</SectionTitle>
                <LineDivider>
                    <StyledLine />
                    <ImFire
                        style={{
                            width: 20,
                            height: 20,
                            color: theme.colors.secondary,
                            marginLeft: 10,
                            marginRight: 10,
                        }}
                    />
                    <StyledLine />
                </LineDivider>
                <SectionText inView={inView}>
                    {replaceTextByHtml ? replaceTextByHTML(text, wordToReplace, link) : text}
                </SectionText>
            </SectionTitleWrapper>
            {children}
        </StyledSection>
    );
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    text: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    inView: PropTypes.bool,
    waves: PropTypes.number,
};

export default Section;
