import styled from 'styled-components';

export const StyledCollapsibleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const CollapsibleSection = styled.p`
    font-size: 15px;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

export const ItemIcon = styled.img`
    width: 30px;
    height: 30px;
    margin: 5px;
`;
