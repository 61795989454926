import styled from 'styled-components';

export const TriggerContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledTrigger = styled.p`
    color: black;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.font.size.md};
    margin: 0;
`;
