import styled from 'styled-components';

export const StyledPage = styled.div`
    background-color: white;
    min-height: 100vh;
    width: 100%;
    position: absolute;
    margin: 0 auto;
`;

export const SectionsWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: 0 auto;
`;
