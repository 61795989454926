import { StyledOption, StyledSelect } from './LanguageSelector.styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = ({ style }) => {
    const { i18n } = useTranslation('common');

    const changeLanguage = language => {
        i18n.changeLanguage(language).then(() => localStorage.setItem('language', language));
    };

    return (
        <StyledSelect style={style} value={i18n.language} onChange={e => changeLanguage(e.target.value)}>
            <StyledOption value={'en'}>{'English'}</StyledOption>
            <StyledOption value={'pl'}>{'Polski'}</StyledOption>
        </StyledSelect>
    );
};

export default LanguageSelector;
