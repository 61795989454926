import React from 'react';
import {
    BoxText,
    BoxTitle,
    CleanCodeBox,
    CleanCodeSection,
    CleanCodeText,
    InfoBox,
    ProgrammingBackgroundColor,
    ProgrammingBackgroundImage,
    ProgrammingDiv,
    ProgrammingImage,
    ProgrammingInfo,
    StyledCheckbox,
} from './ProgrammingBox.styles';
import wallpaperProgramming from '../../../assets/images/wallpapers/wallpaper2.png';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import playAnimations from '../../../utils/helpers/playAnimations';
import useMobileWidth from '../../../hooks/useMobileWidth';

const ProgrammingBox = () => {
    const { t } = useTranslation('common');
    const isMobile = useMobileWidth();

    const { ref, inView } = useInView({
        threshold: 0.45,
        triggerOnce: true,
    });

    return (
        <ProgrammingDiv ref={ref}>
            <ProgrammingImage inView={playAnimations(inView, isMobile)} src={wallpaperProgramming} />
            <ProgrammingInfo inView={playAnimations(inView, isMobile)}>
                <ProgrammingBackgroundImage />
                <ProgrammingBackgroundColor />
                <InfoBox>
                    <BoxTitle>{t('programming_text_1')}</BoxTitle>
                    <BoxText>{t('programming_text_2')}</BoxText>
                    <CleanCodeSection>
                        <CleanCodeBox>
                            <StyledCheckbox type={'checkbox'} defaultChecked disabled />
                            <CleanCodeText>{'DRY.'}</CleanCodeText>
                        </CleanCodeBox>
                        <CleanCodeBox>
                            <StyledCheckbox type={'checkbox'} defaultChecked disabled />
                            <CleanCodeText>{'KISS.'}</CleanCodeText>
                        </CleanCodeBox>
                        <CleanCodeBox>
                            <StyledCheckbox type={'checkbox'} defaultChecked disabled />
                            <CleanCodeText>{'YAGNI.'}</CleanCodeText>
                        </CleanCodeBox>
                    </CleanCodeSection>
                </InfoBox>
            </ProgrammingInfo>
        </ProgrammingDiv>
    );
};

export default ProgrammingBox;
