import Layout from '../components/Layout/Layout';
import React from 'react';
import Intro from '../sections/Intro/Intro';
import Projects from '../sections/Projects/Projects';
import Skills from '../sections/Skills/Skills';
import Hobby from '../sections/Hobby/Hobby';
import Contact from '../sections/Contact/Contact';
import ScrollToUp from '../components/ScrollToUp/ScrollToUp';

const Home = () => {
    return (
        <Layout title={'Home'}>
            <Intro />
            <Projects />
            <Skills />
            <Hobby />
            <Contact />
            <ScrollToUp />
        </Layout>
    );
};

export default Home;
