export const theme = {
    font: {
        size: {
            sm: '12px',
            md: '14px',
            lg: '16px',
            xl: '22px',
        },
        family: {
            Roboto: 'Roboto, serif',
            RobotoCondensed: 'Roboto Condensed, serif',
            Helvetica: 'Helvetica, serif',
            PressStart: 'Press Start, serif',
        },
        weight: {
            thin: 100,
            light: 300,
            regular: 400,
            bold: 700,
        },
    },
    colors: {
        primary: '#FFFFFF',
        secondary: '#DC143C',
    },
    mediaBreakpoints: {
        navbarChange: '@media all and (max-width: 1280px)',
        mobile: '@media all and (max-width: 360px)',
        sm: '@media all and (max-width: 576px)',
        md: '@media all and (max-width: 768px)',
        lg: '@media all and (max-width: 992px)',
        xl: '@media all and (max-width: 1200px)',
        xxl: '@media all and (max-width: 1400px)',
    },
};
