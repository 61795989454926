import { useState, useEffect } from 'react';

const useScrollIndicator = scrollPosition => {
    const [scrollState, setScrollState] = useState('top');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const checkScrollPosition = () => {
                let scrolled = document.scrollingElement.scrollTop;
                if (scrolled >= scrollPosition) {
                    if (scrollState !== 'scrolled') {
                        setScrollState('scrolled');
                    }
                } else {
                    if (scrollState !== 'top') {
                        setScrollState('top');
                    }
                }
            };

            window.addEventListener('scroll', checkScrollPosition);

            return () => {
                window.removeEventListener('scroll', checkScrollPosition);
            };
        }
    }, [scrollPosition, scrollState]);

    return scrollState;
};

export default useScrollIndicator;
