import styled from 'styled-components';

export const SkillsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 60px;
    margin-bottom: 30px;
    flex-wrap: wrap;
`;
