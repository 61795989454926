import React from 'react';
import contact from '../../utils/data/contact';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { DiscordIconWrapper } from './DiscordIcon.styles';
import './DiscordIcon.styles.css';

export const DiscordIcon = ({ width, height, itemId }) => {
    const { t } = useTranslation('common');

    const copyName = () => {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.value = contact.discord;
        input.select();
        document.execCommand('copy', false);
        input.remove();

        const tooltipText = document.getElementById(itemId);
        tooltipText.innerHTML =
            '<p style="color: orange; display: inline; width: 80%;">' +
            contact.discord +
            ' <span style="color: white">' +
            t('copied_text') +
            '</span></p>';
    };

    const outCopyName = () => {
        const tooltipText = document.getElementById(itemId);
        tooltipText.innerHTML = '<span>' + t('copy_text') + '</span>';
    };

    return (
        <DiscordIconWrapper className={'tooltip-class'} width={width} height={height}>
            <button
                id={'discordIcon'}
                onClick={() => copyName()}
                onMouseOut={() => outCopyName()}
                color={'white'}
                style={{
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <p id={itemId} className={'tooltip-text'}>
                    {t('copy_text')}
                </p>
                <FontAwesomeIcon
                    icon={faDiscord}
                    type={'discord'}
                    className={'icon'}
                    style={width && height ? { width: width - 12, height: height - 12 } : {}}
                />
            </button>
        </DiscordIconWrapper>
    );
};
