import React from 'react';
import StyledSliderContent from './SliderContent.styles';

const SliderContent = ({ children, translate, transition, width }) => {
    return (
        <StyledSliderContent translate={translate} transition={transition} width={width}>
            {children}
        </StyledSliderContent>
    );
};

export default SliderContent;
