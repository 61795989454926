import styled from 'styled-components';

export const StyledFooter = styled.footer`
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    text-align: center;
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: ${({ theme }) => theme.font.size.sm};
    background-color: #2d3436;
    background-image: linear-gradient(to right, #ffffff, #ededed, #ffffff);
    border-top: 1px solid black;
`;

export const FooterText = styled.p`
    color: grey;
    margin: 0;
`;

export const FooterIcons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
`;

export const FooterLink = styled.a`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: black;
    cursor: pointer;
    padding-bottom: 2px;
    background-image: linear-gradient(#dc143c, #dc143c);
    background-position: 0 100%;
    background-size: 0% 1px;
    background-repeat: no-repeat;
    transition: background-size 0.3s, background-position 0s 0.3s, color 0.3s;
    margin-top: 10px;

    &:hover {
        color: #dc143c;
    }

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        font-size: 10px;
    }
`;

export const FooterNav = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    flex-wrap: wrap;

    & ${FooterLink}:first-child {
        margin-left: 20px;
    }

    & ${FooterLink}:after {
        content: ' // ';
        padding-left: 10px;
        padding-right: 10px;
        color: ${({ theme }) => theme.colors.secondary};
    }

    & ${FooterLink}:last-child:after {
        content: '';
    }
`;
