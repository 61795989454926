import styled from 'styled-components';

const iconColors = {
    email: '#8b0000',
    github: '#696969',
    linkedin: '#0e76a8',
};

export const StyledIcon = styled.a`
    width: ${({ width }) => width ?? 75}px;
    height: ${({ height }) => height ?? 75}px;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #888;
    background-image: linear-gradient(
        ${({ iconType }) => iconColors[iconType]},
        ${({ iconType }) => iconColors[iconType]}
    );
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: all 1s ease-in-out;

    &:hover {
        background-size: 100% 100%;
    }

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        transform: scale(0.8);
        margin: 0;
    }
`;
