import { WebsiteDesc, WebsiteHoverContent, WebsiteStyledItem, WebsiteTitle } from './WebsiteItem.styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const WebsiteItem = ({ name, description, websiteImage, url, color, backgroundColor, style }) => {
    const [hover, setHover] = useState(false);
    const { i18n } = useTranslation('common');

    return (
        <WebsiteStyledItem
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            target={'_blank'}
            href={url}
            websiteImage={websiteImage}
            backgroundColor={backgroundColor}
            style={style}>
            {hover && (
                <WebsiteHoverContent>
                    <WebsiteTitle color={color}>{name}</WebsiteTitle>
                    <WebsiteDesc>{description[i18n.language]}</WebsiteDesc>
                </WebsiteHoverContent>
            )}
        </WebsiteStyledItem>
    );
};

WebsiteItem.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    websiteImage: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    style: PropTypes.object,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
};

export default WebsiteItem;
