import styled, { css } from 'styled-components';

export const ScrollToUpFab = styled.div`
    opacity: 0;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.secondary};
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    visibility: hidden;

    &:hover {
        background-color: black;
    }

    ${({ scrollPos }) =>
        scrollPos === 'scrolled' &&
        css`
            opacity: 1;
            visibility: visible;
        `};
`;
