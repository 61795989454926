import Section from '../../../components/Section/Section';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { CompetitionContainer } from './CompetitionsSection.styles';
import { competitionsProjects } from '../../../utils/data/projects';
import CompetitionItem from './CompetitionItem/CompetitionItem';
import playAnimations from '../../../utils/helpers/playAnimations';
import contact from '../../../utils/data/contact';
import useMobileWidth from '../../../hooks/useMobileWidth';

const CompetitionsSection = () => {
    const { t } = useTranslation('common');
    const isMobile = useMobileWidth();

    const renderCompetitions = () => {
        return competitionsProjects.map((e, index) => (
            <CompetitionItem
                key={index}
                order={index % 2 === 0 ? 'left' : 'right'}
                image={e.image}
                name={e.name}
                links={e.links}
                description={e.description}
                competition={e.competition}
                technologies={e.technologies}
            />
        ));
    };

    const { ref, inView } = useInView({
        threshold: 0.45,
        triggerOnce: true,
    });

    return (
        <div ref={ref}>
            <Section
                waves={1}
                inView={playAnimations(inView, isMobile)}
                subtitle={t('projects')}
                title={t('projects_competitions')}
                text={t('projects_competitions_text')}
                replaceTextByHtml={{
                    wordToReplace: 'npm',
                    link: contact.npm,
                }}>
                <CompetitionContainer inView={playAnimations(inView, isMobile)}>
                    {renderCompetitions()}
                </CompetitionContainer>
            </Section>
        </div>
    );
};

export default CompetitionsSection;
