import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledCompetitionItem = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 50px;
    margin-right: 50px;
    transition: all 2s ease-in-out;

    @media (max-width: 1024px) {
        flex-direction: column;

        &:not(:last-child) {
            margin-bottom: 80px;
        }
    }
`;

export const ProjectImage = styled.div`
    flex: 2;
    width: 100%;
    max-width: 500px;
    min-height: 300px;
    background-image: url(${({ image }) => image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    align-self: center;
    order: ${({ order }) => (order === 'left' ? 0 : 1)};

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        order: 0;
    }
`;

export const ProjectInfo = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 30px;
    width: 100%;
    order: ${({ order }) => (order === 'left' ? 1 : 0)};

    @media (max-width: 1024px) {
        padding: 0;
        order: 1;
    }
`;

export const LinksBlock = styled.div`
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

export const BottomItem = styled.div`
    display: flex;
    margin-top: auto;
    align-items: center;
`;

export const Line = styled.div`
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    width: 2px;
    height: 30px;
    background-image: linear-gradient(to right, #0f0f0f, grey, #0f0f0f);
    transform: rotate(30deg);
`;

export const IconWrapper = styled.a`
    width: 40px;
    height: 40px;
    background-color: ${({ backgroundColor }) => backgroundColor ?? '#ebebeb90'};
    border: 1px solid ${({ borderColor }) => borderColor ?? '#ebebeb'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-radius: 50%;
    z-index: 1;
    transition: all 1s;

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.secondary + 90};
    }
`;

export const IconTechWrapper = styled.div`
    width: 40px;
    height: 40px;
    background-color: #ebebeb90;
    border: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-radius: 50%;
    z-index: 1;
`;

export const ProjectTitle = styled.p`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: 25px;
    line-height: 30px;
    border-left-width: 10px;
    border-left-style: solid;
    border-left-color: ${({ theme }) => theme.colors.secondary};
    padding-left: 7px;
    margin-bottom: 20px;
    text-align: left;
`;

export const CompetitionTitle = styled.span`
    font-size: ${({ theme }) => theme.font.size.md};
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    color: #c0c0c0;
`;

export const CompetitionInfo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

export const CompetitionSubtitle = styled.span`
    font-size: ${({ theme }) => theme.font.size.md};
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    color: grey;
    font-style: italic;
`;

export const ProjectDesc = styled.p`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-size: 15px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: black;
    text-align: left;
    margin-top: 20px;
    width: 90%;
    line-height: 1.3;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const AwardIcon = styled(FontAwesomeIcon)`
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.secondary};
    margin-right: 5px;
`;

export const CompetitionIcon = styled.img`
    height: 16px;
    margin-right: 5px;
`;

export const StyledIcon = styled.img`
    width: 25px;
    height: 25px;
`;
