import styled from 'styled-components';
import wallpaperMe from '../../../assets/images/wallpapers/wallpaper.png';

export const StatsDiv = styled.div`
    width: 100%;
    height: 100%;
    min-height: 450px;
    background-color: ${({ theme }) => theme.colors.secondary};
    position: relative;
`;

export const WallpaperImage = styled.div`
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-image: url(${wallpaperMe});
    position: absolute;
    opacity: 0.4;
`;

export const ImageBackground = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(
        ${({ theme }) => theme.colors.secondary + 45},
        ${({ theme }) => theme.colors.secondary + 80}
    );
`;

export const StatsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 450px;
    height: 100%;
    flex-wrap: wrap;
`;

export const StatBox = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: black;
    height: 200px;
    margin: 50px;
    border-radius: 10px;
    padding: 15px;
`;

export const StatValue = styled.p`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-size: 90px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: white;
    z-index: 2;
    margin: 0;
    flex: 1;
`;

export const StatDesc = styled.p`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: #e0e0e0;
    z-index: 2;
    margin: 0;
    margin-top: 15px;
    text-align: center;
    flex: 2;
`;
