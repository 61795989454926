import { IoCloseOutline } from 'react-icons/io5';
import styled, { css, keyframes } from 'styled-components';

const revealText = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const projectAnimation = keyframes`
    0% {
        transform: skewY(10deg) scale(0.9);
    }

    50% {
        transform: scale(1.2);
    }
    
    100% {
        transform: scale(1);
    }
`;

export const StyledSlide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ sliderType }) =>
        sliderType === 'projects' &&
        css`
            height: 100%;
            width: 100%;
            position: relative;
            background: linear-gradient(#000000, #1f1f1f);
            opacity: ${({ slideOpacity }) => (slideOpacity ? 1 : 0.1)};

            ${({ theme }) => theme.mediaBreakpoints.lg} {
                flex-direction: column;
            }
        `}

    ${({ sliderType }) =>
        sliderType === 'hobby' &&
        css`
            height: 100%;
            width: 100%;
            position: relative;
            background-color: black;
            opacity: ${({ slideOpacity }) => (slideOpacity ? 1 : 0.1)};
            flex-direction: column;
        `}
`;

export const ProjectImageContainer = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
`;

export const ProjectImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    animation: ${projectAnimation} 6s ease-in-out forwards;
    opacity: 0.9;
    max-height: 500px;
    pointer-events: none;
`;

export const ProjectOverviewContainer = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        order: 1;
        margin: 0;
        width: 80%;
        justify-content: start;
    }
`;

export const ProjectTitle = styled.p`
    font-size: 28px;
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    color: white;
    border-left: 10px solid ${({ theme }) => theme.colors.secondary};
    text-align: left;
    padding-left: 10px;
    opacity: 0;
    animation: ${revealText} 2s ease-in-out forwards;

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: 26px;
    }
`;

export const ProjectText = styled.p`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: ${({ textSize, theme }) => (textSize === 'small' ? theme.font.size.md : theme.font.size.lg)};
    color: #ebebeb;
    text-align: left;
    opacity: 0;
    animation: ${revealText} 2s ease-in-out forwards;
    animation-delay: 2s;
    width: 100%;
    line-height: 1.3;

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: ${({ theme }) => theme.font.size.sm};
    }
`;

export const ProjectInfo = styled.div`
    display: flex;

    ${({ theme }) => theme.mediaBreakpoints.lg} {
        flex-direction: column;
    }
`;

export const TechnologyInfo = styled.div`
    font-family: ${({ theme }) => theme.font.family.Roboto};
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: ${({ theme }) => theme.font.size.md};
    color: white;
    text-align: left;
    opacity: 0;
    animation: ${revealText} 2s ease-in-out forwards;
    animation-delay: 2s;
    width: 100%;
    line-height: 1.3;
    margin: 0;
    display: flex;

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: ${({ theme }) => theme.font.size.sm};
    }
`;

export const ProjectType = styled.p`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: ${({ theme }) => theme.font.size.lg};
    color: grey;
    text-align: left;
    opacity: 0;
    animation: ${revealText} 2s ease-in-out forwards;
    animation-delay: 1s;

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const VideoContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

export const StyledVideo = styled.video`
    filter: brightness(30%);
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

export const StyledVideoFront = styled.div`
    background: repeating-linear-gradient(to bottom, #29262190, #29262190 2px, #33302a90 2px, #33302a90 4px);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

export const HobbyTitle = styled.p`
    font-size: 30px;
    color: white;
    z-index: 1;
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: 26px;
    }
`;

export const HobbyQuote = styled.p`
    font-size: ${({ theme }) => theme.font.size.lg};
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    color: #999;
    letter-spacing: 2px;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    width: 70%;
    z-index: 1;
    margin-bottom: 30px;

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const HobbyText = styled.p`
    font-size: ${({ theme }) => theme.font.size.lg};
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    color: white;
    font-weight: ${({ theme }) => theme.font.weight.light};
    width: 70%;
    z-index: 1;
    line-height: 1.3;

    ${({ theme }) => theme.mediaBreakpoints.md} {
        width: 90%;
    }

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        font-size: ${({ theme }) => theme.font.size.md};
    }
`;

export const HobbyKeywords = styled.div`
    display: flex;
    z-index: 1;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
`;

export const HobbyKeyword = styled.p`
    font-family: ${({ theme }) => theme.font.family.PressStart};
    font-size: 10px;
    color: white;
`;

export const ProjectContent = styled.div`
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    z-index: ${({ isOpen }) => (isOpen ? 2 : -2)};
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000000, #1f1f1f);
    transition: all 1s ease-in-out;
`;

export const ProjectInfoContainer = styled.div`
    margin-top: 50px;
    margin-left: 90px;
    margin-right: 90px;

    ${({ theme }) => theme.mediaBreakpoints.md} {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const ProjectInfoBlock = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    ${({ withMargin }) =>
        withMargin &&
        css`
            margin-left: 50px;

            ${({ theme }) => theme.mediaBreakpoints.lg} {
                margin-left: 0;
            }
        `};
`;

export const InfoType = styled.p`
    color: ${({ theme }) => theme.colors.secondary};
    flex: 1;

    ${({ minWidth }) =>
        minWidth &&
        css`
            min-width: ${minWidth}px;
        `};
`;

export const InfoText = styled.p`
    flex: 3;
    line-height: 1.5;
`;

export const CloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 40px;
    height: 40px;
    border: 2px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 50%;
    position: absolute;
    right: 50px;
    top: 20px;
    cursor: pointer;

    @media (max-width: 992px) {
        top: 16px;
        right: 16px;
        transform: scale(0.9);
    }
`;

export const CloseIcon = styled(IoCloseOutline)`
    width: 30px;
    height: 30px;

    @media (max-width: 992px) {
        transform: scale(0.9);
    }
`;
